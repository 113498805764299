<template>
  <ButtonComponent tag="label" color="transparent" for="video">
    Добавить видео
    <input
      hidden
      id="video"
      type="file"
      accept="video/*"
      @input="onInput($event)"
    />
  </ButtonComponent>
</template>
<script>
import {mapActions, mapState} from 'pinia';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'UploadVideoButton',
  components: {ButtonComponent},
  computed: {
    ...mapState(useVideoUploadStore, ['uploader']),
    ...mapState(useGirlProfileStore, ['id']),
  },
  methods: {
    ...mapActions(useVideoUploadStore, ['initUploader', 'addFile']),
    onInput(event) {
      this.addFile(event.target.files[0]);
      event.target.value = '';
    },
  },
  watch: {
    id() {
      this.initUploader(this.$refs.browse);
    },
  },
  mounted() {
    if (this.id) {
      this.initUploader(this.$refs.browse);
    }
  },
};
</script>
