<template>
  <div class="photo-verification">
    <div class="relative photo-verification-preview">
      <upload-image-preview class="" :src="image || imageExample" />
      <div v-if="isLoadingImage" class="verification-loader">
        <i class="i-spinner rotating text-white" />
      </div>
      <slot />
    </div>
    <p class="device-error" v-if="showDeviceTypeError">
      Упс, ошибка... Попробуй с телефона
    </p>
    <input-image
      :id="id"
      capture="user"
      @attached="showImage"
      @loadstart="isLoadingImage = true"
      @loadend="isLoadingImage = false"
      @input="$emit('input', $event)"
    />
    <div class="photo-verification-description">
      <p v-if="comment" class="text-red mb-8">{{ comment }}</p>
      <p v-if="description">{{ description }}</p>
    </div>
    <ButtonComponent
      tag="label"
      :for="id"
      class-name="mb-8"
      :color="hasPhotoAttached ? 'transparent' : 'primary'"
      @click="onInput($event)"
      >{{ selectBtnText }}</ButtonComponent
    >
    <ButtonComponent
      :disabled="!hasPhotoAttached"
      :loading="isSending"
      :color="hasPhotoAttached ? 'primary' : 'transparent'"
      @click="$emit('submit')"
    >
      Следующий шаг
    </ButtonComponent>
  </div>
</template>

<script>
import UploadImagePreview from '@/components/UploadImagePreview.vue';
import InputImage from '@/components/forms/InputImage.vue';
import isMobile from 'ismobilejs';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'PhotoVerification',
  components: {
    InputImage,
    UploadImagePreview,
    ButtonComponent,
  },
  props: {
    id: String,
    comment: String,
    description: String,
    imageExample: String,
    isSending: Boolean,
    submitBtnText: {
      type: String,
      default: 'Отправить',
    },
  },
  data() {
    return {
      image: '',
      isLoadingImage: false,
      hasPhotoAttached: false,
      showDeviceTypeError: false,
    };
  },
  computed: {
    selectBtnText() {
      return this.hasPhotoAttached ? 'Сделать другое фото' : 'Сделать фото';
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent;
      return isMobile(userAgent).any;
    },
  },
  methods: {
    onInput(e) {
      if (!this.isMobileDevice) {
        e.preventDefault();
        this.showDeviceTypeError = true;
      }
    },
    showImage(e) {
      this.image = e;
      this.hasPhotoAttached = true;
    },
    async onChange(e) {
      this.isLoading = true;
      const {valid} = await this.$refs.input.validate(e);
      if (valid) {
        this.$refs.image.src = await this.getImageSrc(e);
        this.isLoading = false;
        this.hasPhotoAttached = true;
      } else {
        this.isLoading = false;
      }
    },
    getImageSrc(e) {
      return new Promise((resolve) => {
        const files = e.target.files;
        if (files && files.length > 0) {
          const file = files[0];
          this.$emit('input', file);
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
  },
};
</script>

<style>
.verification-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.photo-verification-preview img {
  width: 100%;
  height: auto;
}

.photo-verification-description {
  padding: 16px 0 36px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.device-error {
  margin-top: 2px;
  font-size: 12px;
  line-height: 15px;
  color: var(--red-secondary);
}
</style>
