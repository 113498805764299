<template>
  <div
    class="control-icon bookmark"
    :class="{active: isFavorite}"
    @click="onFavorite"
  />
</template>

<script>
export default {
  name: 'PostFavorite',
  props: {
    isFavorite: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onFavorite() {
      console.log('favorite!');
    },
  },
};
</script>
