import {defineStore} from 'pinia';
import createPostApi from '@/http/girl/createPost';

export const useCreatePostStore = defineStore('createPost', {
  state: () => ({
    mode: '',
    audienceList: {},
    postId: null,
    previews: [],
    preview_url: null,
    fullBase64: '',
    croppedBase64: '',
    post: {
      audience: 'all',
      description: null,
      photo: null,
      photo_id: null,
      video: null,
      video_id: null,
      video_preview_id: null,
    },
    isSending: false,
    showValidationDialog: false,
    validationError: {
      message: '',
      description: '',
    },
    showCreatePostDialog: false,
  }),
  getters: {
    contentType: (state) => {
      const {photo, photo_id, video, video_id} = state.post;
      if (photo || photo_id) {
        return 'photo';
      }
      if (video || video_id) {
        return 'video';
      }
      return null;
    },
    photoAsFile: (state) => typeof state.post.photo !== 'string',
    videoAsLink: (state) => state.post.video,
    hasSelectedPreview: (state) => Boolean(state.post.video_preview_id),
    hasAudienceList: (state) => Object.keys(state.audienceList).length > 0,
    isCreating: (state) => state.mode !== 'edit',
    isEditing: (state) => state.mode === 'edit',
  },
  actions: {
    openCreatePostDialog() {
      this.showCreatePostDialog = true;
    },
    closeCreatePostDialog() {
      this.showCreatePostDialog = false;
    },
    showValidationError(message, description) {
      this.validationError.message = message;
      this.validationError.description = description;
      this.showValidationDialog = true;
    },
    hideValidationDialog() {
      this.validationError.message = '';
      this.validationError.description = '';
      this.showValidationDialog = false;
    },
    setVideo(videoId, previews) {
      this.post.video_id = videoId;
      this.previews = previews;
    },
    choosePreview(index) {
      this.post.video_preview_id = this.previews[index].preview_id;
      this.preview_url = this.previews[index].url;
      this.previews = [];
    },
    reset() {
      this.postId = null;
      this.previews = [];
      this.fullBase64 = '';
      this.croppedBase64 = '';
      this.preview_url = null;
      this.post = {
        id: null,
        audience: 'all',
        description: '',
        photo: null,
        photo_id: null,
        video: null,
        video_id: null,
        video_preview_id: null,
      };
    },
    resetPhoto() {
      this.post.photo = null;
      this.post.photo_id = null;
    },
    resetVideo() {
      this.post.video = null;
      this.post.video_id = null;
      this.post.video_preview_id = null;
      this.previews = [];
      this.preview_url = null;
    },
    async getAudienceList() {
      if (this.hasAudienceList) {
        return;
      }
      const result = await createPostApi.getPostAudienceList();
      if (result) {
        const audienceList = {};
        result.forEach((item) => {
          Object.assign(audienceList, item);
        });
        this.audienceList = audienceList;
      }
    },
    setPost(post, postId) {
      this.mode = 'edit';
      this.reset();
      this.postId = postId;
      this.post.description = post.description;
      Object.assign(this.post, post);
      this.openCreatePostDialog();
    },
    setMode(mode) {
      this.mode = mode;
    },
    removeEmptyProps() {
      for (const [key, value] of Object.entries(this.post)) {
        if (!Boolean(value)) {
          delete this.post[key];
        }
      }
    },
    prepareFormData() {
      this.removeEmptyProps();
      if (typeof this.post.photo === 'string') {
        delete this.post.photo;
      }
      const formData = new FormData();
      for (const [key, value] of Object.entries(this.post)) {
        if (key === 'crop' && value) {
          for (const [cropKey, cropValue] of Object.entries(value)) formData.append(`crop[${cropKey}]`, cropValue);
        } else {
          formData.append(key, value);
        }
      }
      return formData;
    },
    async submitPostWithPhoto() {
      this.isSending = true;
      try {
        const formData = this.prepareFormData();
        await createPostApi.createPostWithPhoto(formData);
        this.reset();
      } finally {
        this.isSending = false;
      }
    },
    async editPostWithPhoto() {
      this.isSending = true;
      try {
        const formData = this.prepareFormData();
        await createPostApi.editPostWithPhoto(this.postId, formData);
        this.reset();
      } finally {
        this.isSending = false;
      }
    },
    async createPostWithVideo() {
      this.isSending = true;
      try {
        const formData = this.prepareFormData();
        await createPostApi.createPostWithVideo(formData);
        this.reset();
      } finally {
        this.isSending = false;
      }
    },
    async editPostWithVideo() {
      this.isSending = true;
      try {
        const formData = this.prepareFormData();
        await createPostApi.editPostWithVideo(this.postId, formData);
        this.reset();
      } finally {
        this.isSending = false;
      }
    },
  },
});
