import {http} from '../index.js';

async function updateProfile(data) {
  return http.post(`/consumer/profile`, data).then((response) => response.data);
}

async function loadAvatar(data) {
  return http
    .post('consumer/avatar', data)
    .then((response) => response.data.data);
}

async function loadCover(data) {
  return http
    .post('consumer/cover', data)
    .then((response) => response.data.data);
}

export {updateProfile, loadAvatar, loadCover};
