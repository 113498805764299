import {http} from '@/http/index.js';

async function enableEmailNotification() {
  return http.put('/girl/email-notifications', {
    email_notification_on: true,
  });
}

async function disableEmailNotification() {
  return http.put('/girl/email-notifications', {
    email_notification_on: false,
  });
}

export {enableEmailNotification, disableEmailNotification};
