import {defineStore} from 'pinia';
import girlApi from '@/http/girl';
import girlProfileApi from '@/http/girl/profile/index.js';
import {getMoreData} from '@/stores/helper.js';

export const useGirlProfileStore = defineStore('girlProfile', {
  state: () => ({
    isProfileLoaded: false,
    about_yourself: '',
    balance: 0,
    id: null,
    nickname: '',
    email: null,
    total_fans: 0,
    total_likes: 0,
    verification_status: null,
    avatar: '',
    avatar_id: null,
    cover: '',
    cover_id: null,
    email_notification_on: null,
    notifications: 0,
    notificationList: null,
    isLoading: false,
  }),
  getters: {
    notificationsHasLoaded: (state) => state.notificationList !== null,
    isVerified: (state) => state.verification_status === 'approved',
    verificationInProcess: (state) => state.verification_status === 'in_process',
  },
  actions: {
    async getProfile() {
      const result = await girlProfileApi.getProfile();
      if (result) {
        this.isProfileLoaded = true;
        Object.assign(this.$state, result);
      }
    },
    async updateProfile(formData) {
      this.isLoading = true;
      const result = await girlProfileApi.updateProfile(formData);
      if (result) {
        Object.assign(this.$state, formData);
      }
      this.isLoading = false;
    },
    async loadAvatar(formData) {
      this.isLoading = true;
      try {
        const result = await girlProfileApi.loadAvatar(formData);
        Object.assign(this.$state, result);
        return result;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async loadCover(formData) {
      this.isLoading = true;
      try {
        const result = await girlProfileApi.loadCover(formData);
        Object.assign(this.$state, result);
        return result;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getNotifications() {
      this.isLoading = true;
      const result = await girlApi.getNotifications();
      if (result) {
        this.notifications = 0;
        this.notificationList = result;
      }
      this.isLoading = false;
    },
    async getMoreNotifications() {
      await getMoreData(this.notificationList, girlApi.getMoreNotifications);
    },
    async enableEmailNotification() {
      const result = await girlApi.enableEmailNotification();
      if (result) {
        this.email_notification_on = true;
      }
    },
    async disableEmailNotification() {
      const result = await girlApi.disableEmailNotification();
      if (result) {
        this.email_notification_on = false;
      }
    },
  },
});
