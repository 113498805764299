import {http} from '@/http/index.js';

async function subscribe(girlId) {
  return http
    .post(`/girls/${girlId}/subscribe`)
    .then((response) => response.data.data);
}

async function unsubscribe(girlId) {
  return http
    .post(`/girls/${girlId}/unsubscribe`)
    .then((response) => response.data.data);
}

export {subscribe, unsubscribe};
