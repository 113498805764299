import {http} from '@/http/index.js';

async function getNotifications() {
  return http.get('/girl/notifications').then((response) => response.data);
}

async function getMoreNotifications(url) {
  return http.get(url).then((response) => response.data);
}

export {getNotifications, getMoreNotifications};
