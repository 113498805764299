<template>
  <collapsed-text
    class="text-with-hashtags-and-mentioned"
    :text="text"
    :preview-letters-count="previewLettersCount"
    :transform-text="formatPreviewText"
    @click.native.prevent="onClick($event)"
  />
</template>

<script>
import CollapsedText from '@/components/CollapsedText.vue';

export default {
  name: 'TextWithHashtagsAndMentioned',
  components: {CollapsedText},
  props: {
    text: String,
    hashtags: Array,
    mentioned: Object,
    previewLettersCount: {
      type: Number,
      default: 80,
    },
  },
  methods: {
    removeLastLetterByMask(str) {
      return str.replace(/(\s|$|,|\.)$/, '');
    },
    formatPreviewText(text) {
      return this.replaceMentioned(this.replaceHashtags(text));
    },
    replaceHashtags(rawDescriptionText) {
      const regex = /#[a-zA-ZА-я\d]+(\s|$|,|\.)/g;
      return rawDescriptionText.replace(regex, (match) => {
        const formattedTag = this.removeLastLetterByMask(match);
        return `<a href="/search/${formattedTag}" data-hashtag="${formattedTag}">${match}</a>`;
      });
    },
    replaceMentioned(rawDescriptionText) {
      const regex = /@[a-zA-ZА-я\d]+(\s|$|,|\.)/g;
      if (this.mentioned?.consumers?.length || this.mentioned?.girls?.length) {
        return rawDescriptionText.replace(regex, (match) => {
          const consumerMention = this.mentioned.consumers?.find((el) => el.nickname === this.removeLastLetterByMask(match).substring(1));
          const girlMention = this.mentioned.girls?.find((el) => el.nickname === this.removeLastLetterByMask(match).substring(1));
          if (consumerMention) {
            return `<a href='/consumer/${consumerMention.user_id}' data-consumer-id='${consumerMention.user_id}'>${match}</a>`;
          }
          if (girlMention) {
            return `<a href='/author/${girlMention.user_id}' data-girl-id='${girlMention.user_id}'>${match}</a>`;
          }
          return match;
        });
      }
      return rawDescriptionText;
    },
    hashtagRedirect(hashtag) {
      this.$router.push({
        name: 'search',
        query: {
          searchValue: hashtag,
        },
      });
    },
    consumerMentionRedirect(consumerId) {
      this.$router.push({
        name: 'consumer-profile',
        params: {id: consumerId},
      });
    },
    girlMentionRedirect(girlId) {
      this.$router.push(`/author/${girlId}`);
    },
    onClick(e) {
      if (e.target.tagName === 'A') {
        const { hashtag, consumerId, girlId } = e.target.dataset;
        if (hashtag) {
          this.hashtagRedirect(hashtag);
          return;
        }
        if (consumerId) {
          this.consumerMentionRedirect(consumerId);
          return;
        }
        if (girlId) {
          this.girlMentionRedirect(girlId);
          return;
        }
      }
    },
  },
};
</script>
