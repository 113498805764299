<template>
  <div class="image-cropper" :class="{'crop-circle': circle}">
    <img src="#" ref="image" alt="" class="w-full" />
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import isTouchDevice from '@/helpers/isTouchDevice.js';

const optionsForTouch = {
  dragMode: 'move',
  viewMode: 1,
  guides: false,
  autoCrop: true,
  restore: false,
  center: false,
  highlight: false,
  cropBoxMovable: false,
  toggleDragModeOnDblclick: false,
};

const optionsForCursor = {
  dragMode: 'move',
  autoCrop: true,
  viewMode: 1,
  center: false,
  guides: false,
};

export default {
  name: 'ImageCropper',
  props: {
    circle: Boolean,
    cropmove: Function,
    crop: {
      type: Function,
      default: () => {},
    },
    aspectRatio: Number,
    cropBoxResizable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cropper: null,
    };
  },
  computed: {
    cropperOptions() {
      const options = isTouchDevice ? optionsForTouch : optionsForCursor;
      return {
        ...options,
        cropBoxResizable: this.cropBoxResizable,
        aspectRatio: this.aspectRatio,
        cropmove: this.cropmove,
        crop: (event) => this.crop(event),
      };
    },
  },
  methods: {
    initCropper(src, customOptions = null) {
      if (this.cropper) {
        return;
      }
      this.$refs.image.src = src;
      const options = customOptions ? customOptions : this.cropperOptions;
      this.cropper = new Cropper(this.$refs.image, options);
    },
    destroyCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    },
    getCroppedData() {
      const {x, y, width, height} = this.cropper.getData();
      return {
        width: Math.round(width),
        height: Math.round(height),
        x: Math.round(x) || 1,
        y: Math.round(y) || 1,
      };
    },
    getCroppedCanvas() {
      const imageData = this.cropper.getImageData();
      return new Promise((resolve) => {
        resolve(
          this.cropper
            .getCroppedCanvas({
              maxWidth: imageData.naturalHeight,
              maxHeight: imageData.naturalWidth,
            })
            .toDataURL()
        );
      });
    },
  },
};
</script>

<style>
.image-cropper {
  max-height: 45vh !important;
}

.cropper-point {
  background: #d9d9d9;
}

.cropper-line {
  background: transparent;
}

.cropper-view-box {
  outline: none;
}

.cropper-modal {
  opacity: 0.6;
}

.cropper-point,
.cropper-point.point-se {
  background: transparent;
  opacity: 1;
  width: 14px;
  height: 14px;
}

.cropper-point.point-nw,
.cropper-point.point-n,
.cropper-point.point-ne {
  top: -7px;
}

.cropper-point.point-ne,
.cropper-point.point-e,
.cropper-point.point-se {
  right: -7px;
}

.cropper-point.point-se,
.cropper-point.point-s,
.cropper-point.point-sw {
  bottom: -7px;
}

.cropper-point.point-nw,
.cropper-point.point-w,
.cropper-point.point-sw {
  left: -7px;
}

.cropper-point.point-n,
.cropper-point.point-s {
  margin: 0;
  transform: translateX(-50%);
}

.cropper-point.point-w,
.cropper-point.point-e {
  margin: 0;
  transform: translateY(-50%);
}

.cropper-point::before,
.cropper-point::after,
.cropper-point.point-se:before {
  opacity: 1;
  content: '';
  background: #d9d9d9;
  display: inline-block;
  position: absolute;
}

.cropper-point.point-nw::after,
.cropper-point.point-n::after,
.cropper-point.point-ne::before {
  width: inherit;
  height: 6px;
  top: 0;
  left: 0;
}

.cropper-point.point-e::after,
.cropper-point.point-ne::after,
.cropper-point.point-se::after {
  width: 6px;
  height: inherit;
  right: 0;
  top: 0;
}

.cropper-point.point-se::before,
.cropper-point.point-s::before,
.cropper-point.point-sw::before {
  width: inherit;
  height: 6px;
  bottom: 0;
  left: 0;
}

.cropper-point.point-nw::before,
.cropper-point.point-w::after,
.cropper-point.point-sw::after {
  width: 6px;
  height: inherit;
  left: 0;
  top: 0;
}

.crop-circle .cropper-view-box,
.crop-circle .cropper-face {
  border-radius: 50%;
  outline: none;
}

.cropper-bg {
  background: #1a1c21;
}
</style>
