<template>
  <div class="network-error">
    <div class="network-error-text">Интернет-соединение потеряно</div>
  </div>
</template>

<script>
export default {
  name: 'NetworkErrorNotification',
};
</script>
