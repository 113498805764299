<template functional>
  <div
    class="create-post-video-processing"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VideoProcessingNotice',
};
</script>

<style>
.create-post-video-processing {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 250px;
  text-align: center;
  background: rgba(25, 28, 34, 0.5);
  border-radius: 100px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  transform: translate(-50%, -50%);
  padding: 6px 8px;
}
</style>
