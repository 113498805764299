<template functional>
  <ul class="validation-list" v-if="props.messages.length">
    <li
      class="validation-list-item"
      v-for="message in props.messages"
      :key="message"
      v-text="message"
    />
  </ul>
</template>

<script>
export default {
  name: 'ValidationMessage',
  props: {
    messages: Array,
  },
};
</script>

<style>
.validation-list {
  margin-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--red-secondary);
}

.validation-list-item:not(:last-child)::after {
  content: ';';
}

.validation-list-item:last-child::after {
  content: '.';
}
</style>
