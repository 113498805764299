<template>
  <div class="like-counter">
    <span class="text-medium">{{ balance }}</span>
    <span class="like-counter-icon like-icon" :class="{active: balance}" />
  </div>
</template>

<script>
import {mapState} from 'pinia';
import {useProfileStore} from '@/stores/profile.js';

export default {
  name: 'LikeBalance',
  computed: {
    ...mapState(useProfileStore, ['balance']),
  },
};
</script>
