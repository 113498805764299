import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import {useModalStore} from '@/stores/modal.js';

const http = axios;

const subscribeErrors = {
  'Unsubscribed: insufficient funds':
    'На балансе не хватает денег, чтобы подключить MyMood. Пополните баланс и повторите попытку.',
  'Unsubscribed: subscription failed':
    'MyMood не подключён. Попробуйте ещё раз позднее или обратитесь в поддержку по бесплатному номеру 8 800 700-06-11.',
};

http.interceptors.response.use(
  (config) => {
    if (import.meta.env.DEV) {
      console.log(config.config.url, config.config.data, config.data.data);
      return config;
    }
    return config;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 404) {
        router.push({
          name: 'error',
          params: {message: 'Страница не найдена'},
        });
      }
      if (error.response.status === 503) {
        router.push({name: 'service-unavailable'});
      }
      if (error.response.status === 401) {
        window.location.replace(import.meta.env.VITE_AUTH_REDIRECT);
      }
      if (error.response.status === 403) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('Subscription Waiting')) {
          const errorRouteName = 'error-subscription-waiting-only-one-girl';
          const messageParts = errorMessage.split(' ');
          const girlNickname = messageParts[messageParts.length - 1];
          if (errorRouteName !== router.currentRoute.name) {
            router.push({
              name: errorRouteName,
              params: {nickname: girlNickname},
            });
          }
        } else if (errorMessage.includes('Subscription Failed')) {
          const messageParts = errorMessage.split(' ');
          const temafonCode = messageParts[messageParts.length - 1];
          router.push({
            name: 'error-subscription-failed-only-one-girl',
            params: {code: temafonCode},
          });
        } else if (errorMessage.includes('unsubscribed to only one girl')) {
          window.location.href = import.meta.env.VITE_SUBS_URL;
        } else if (Object.keys(subscribeErrors).includes(errorMessage)) {
          router.push({
            name: 'error-subscribe',
            params: {
              message: subscribeErrors[errorMessage],
            },
          });
        } else {
          window.location.replace(import.meta.env.VITE_AUTH_REDIRECT);
        }
      }
    }
    if (error.code === 'ERR_NETWORK') {
      useModalStore().showNetworkError();
    }
    return Promise.reject(error);
  }
);

http.defaults.baseURL = import.meta.env.VITE_API_URL;

Vue.axios = http;

export {http};
export default {
  root: import.meta.env.VITE_API_URL,
};
