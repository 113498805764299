const routes = [
  {
    path: '/author/:id',
    component: () => import('@/views/Girl/Index.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/author/statistics/withdraw-history',
        name: 'withdraw-history',
        component: () => import('@/views/Girl/WithdrawHistoryView.vue'),
        meta: {
          layout: 'arrowBack',
          title: 'История выплат',
        },
      },
      {
        path: '/author/regulations',
        name: 'author-regulations',
        component: () => import('@/views/Girl/RegulationsView.vue'),
        meta: {
          layout: 'arrowBack',
          title: 'Пользовательское соглашение',
        },
      },
      {
        path: '/author/statistics',
        name: 'author-statistics',
        component: () => import('@/views/Girl/StatisticsView.vue'),
        meta: {
          layout: 'arrowBack',
          title: 'Личный кабинет',
        },
      },
      {
        path: '/author/withdrawal',
        name: 'author-withdrawal',
        component: () => import('@/views/Girl/WithdrawalView.vue'),
        meta: {
          layout: 'arrowBack',
          title: 'Вывод средств',
        },
      },
      {
        path: '/author/notifications',
        name: 'author-notifications',
        component: () => import('@/views/Girl/NotificationsView.vue'),
      },
      {
        path: '/author/profile/edit',
        name: 'author-profile-edit',
        component: () => import('@/views/Girl/ProfileEditView.vue'),
        meta: {
          layout: 'empty',
        },
      },
      {
        path: '',
        component: () => import('@/views/Girl/ProfileView.vue'),
        children: [
          {
            path: '',
            name: 'author-profile',
            component: () => import('@/views/Girl/PostsTab.vue'),
          },
          {
            path: 'author-reactions',
            name: 'author-reactions',
            component: () => import('@/views/Girl/ReactionsTab.vue'),
          },
        ],
      },
      {
        path: 'posts',
        name: 'author-posts',
        component: () => import('@/views/Girl/PostsView.vue'),
        meta: {
          layout: 'balanceWithMenu',
          title: 'Посты',
        },
      },
      {
        path: 'fans',
        name: 'author-fans',
        component: () => import('@/views/Girl/FansView.vue'),
      },
      {
        path: 'likes',
        name: 'author-likes',
        component: () => import('@/views/Girl/LikesView.vue'),
      },
    ],
  },
  {
    path: '/author/start-waiting-subscription/:code',
    name: 'author-start-waiting-subscription',
    component: () => import('@/views/Consumer/StartWaitingSubscriptionView.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
  {
    path: '/sign-in-as-test-girl',
    name: 'sign-in-as-test-girl',
    component: () => import('@/views/Girl/SignInAsTestGirl.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
];

export default routes;
