import {defineStore} from 'pinia';
import postsApi from '@/http/posts';
import posts, {getMoreData} from '@/stores/helper.js';

export const useSearchStore = defineStore('search', {
  state: () => ({
    searchValue: '',
    posts: null,
    recommendedPosts: null,
    isLoading: false,
  }),
  getters: {
    postsHasLoaded: (state) => state.posts !== null,
    recommendedPostsHasLoaded: (state) => state.recommendedPosts !== null,
    isEnoughCharactersForSearch() {
      return this.searchValue?.length > 3;
    },
  },
  actions: {
    resetValue() {
      this.searchValue = '';
      this.posts.data = [];
    },
    updateLikes(post) {
      posts.findPostAndUpdateLikesValue(this.posts, post);
      posts.findPostAndUpdateLikesValue(this.recommendedPosts, post);
    },
    applyBlurToPostsOnlyForFans(girlId) {
      if (this.posts) {
        posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId);
      }
      if (this.recommendedPosts) {
        posts.mediaContentVisibleOnlyForFans(
          this.recommendedPosts.data,
          girlId
        );
      }
    },
    removeBlurFromPosts(girlId) {
      if (this.posts) {
        posts.medialContentVisibleForAllConsumers(this.posts.data, girlId);
      }
      if (this.recommendedPosts) {
        posts.medialContentVisibleForAllConsumers(
          this.recommendedPosts.data,
          girlId
        );
      }
    },
    turnOnWaitingSubscription(girlId) {
      if (this.posts) {
        posts.turnOnWaitingSubscription(this.posts.data, girlId);
      }
      if (this.recommendedPosts) {
        posts.turnOnWaitingSubscription(this.recommendedPosts.data, girlId);
      }
    },
    async getRecommendedPosts() {
      this.isLoading = true;
      const result = await postsApi.getRecommendedPost();
      if (result) {
        this.recommendedPosts = result;
      }
      this.isLoading = false;
    },
    async getMoreRecommendedPosts() {
      await getMoreData(this.recommendedPosts, postsApi.getMoreRecommendedPost);
    },
    async searchPostsByDescription() {
      if (this.isEnoughCharactersForSearch) {
        this.isLoading = true;
        const result = await postsApi.searchPostsByDescription(
          this.searchValue
        );
        if (result) {
          this.posts = result;
        }
      } else {
        this.posts = null;
      }
      this.isLoading = false;
    },
    async getMorePosts() {
      await getMoreData(this.posts, postsApi.getMorePosts);
    },
    removePostFromList(postId) {
      // posts.removePostFromList(this.posts.data, postId);
      posts.removePostFromList(this.recommendedPosts, postId);
    },
  },
});
