import {defineStore} from 'pinia';
import Resumable from 'resumablejs';
import Vue from 'vue';
import createPostApi from '@/http/girl/createPost';
import {useCreatePostStore} from '@/stores/createPost.js';

export const useVideoUploadStore = defineStore('videoUpload', {
  state: () => ({
    uploader: null,
    files: [],
    progress: 0,
    isVideoUploading: false,
    isVideoUploaded: false,
  }),
  actions: {
    initUploader() {
      this.uploader = new Resumable({
        target: `${import.meta.env.VITE_API_URL}girls/posts-videos`,
        maxChunkRetries: 1,
        maxFileSize: 2147483648,
        maxFileSizeErrorCallback: () => {
          useCreatePostStore().showValidationError(
            'Видео слишком большое. Максимальный размер — 2 Гб.',
            'Попробуй уменьшить размер в каком-нибудь приложением.'
          );
        },
        fileTypeErrorCallback: () => {
          useCreatePostStore().showValidationError(
            'Неизвестный формат файла. Допустимые форматы: MP4, MOV.',
            'Переделай в один из этих форматов в каким-нибудь приложении.'
          );
        },
        testChunks: false,
        fileType: ['mp4', 'mov'],
        fileParameterName: 'video',
        headers: {
          Authorization: `Bearer ${Vue.auth.token()}`,
          Accept: 'application/json',
        },
      });

      this.uploader.on('fileAdded', async (file) => {
        const videoDuration = await this.checkVideoDuration(file);
        if (videoDuration > 30) {
          useCreatePostStore().showValidationError(
            'Видео слишком длинное. Максимальная длительность — 30 секунд.',
            'Сделай покороче каким-нибудь приложением.'
          );
          return;
        }

        this.files.push({
          file,
          status: 'uploading',
          progress: 0,
        });
        this.uploader.upload();
      });
      this.uploader.on('fileSuccess', (file) => {
        const chunks = file.chunks;
        const lastChunk = chunks[chunks.length - 1];
        const lastChunkResponse = JSON.parse(lastChunk.xhr.response);
        const {video_id, previews} = lastChunkResponse;
        const createPostStore = useCreatePostStore();
        createPostStore.setVideo(video_id, previews);
      });
      this.uploader.on('fileProgress', (file) => {
        this.progress = Math.trunc(file.progress().toFixed(2) * 100);
      });
      this.uploader.on('uploadStart', () => {
        this.isVideoUploading = true;
      });
      this.uploader.on('complete', () => {
        this.isVideoUploading = false;
        this.isVideoUploaded = true;
      });
    },
    addFile(file) {
      this.uploader.addFile(file);
    },
    resetVideo() {
      this.files = [];
      this.progress = 0;
    },
    async deleteVideo(videoId) {
      await createPostApi.deleteVideo(videoId);
      this.resetVideo();
    },
    cancelUpload() {
      this.uploader.cancel();
      this.resetVideo();
    },
    checkVideoDuration(file) {
      return new Promise((resolve) => {
        window.URL = window.URL || window.webkitURL;
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          resolve(Math.trunc(video.duration));
        };
        video.src = URL.createObjectURL(file.file);
      });
    },
  },
});
