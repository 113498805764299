const authPlugin = {
  request: function (req, token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  },
  response: function () {
    const token = window.localStorage.auth_token_default;
    if (token) {
      return token;
    }
  },
};

export default authPlugin;
