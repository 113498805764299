import {
  getFavoriteComments,
  getMoreFavoriteComments,
} from '@/http/girl/comments.js';
import {getFans, getMoreFans} from '@/http/girl/fans.js';
import {getLikes, getMoreLikes} from '@/http/girl/likes.js';
import {getPosts, getMorePosts} from '@/http/girl/posts.js';
import {getProfile} from '@/http/girl/profile.js';
import {subscribe, unsubscribe} from '@/http/girl/subscription.js';
import {
  getNotifications,
  getMoreNotifications,
} from '@/http/girl/notifications.js';
import {
  enableEmailNotification,
  disableEmailNotification,
} from '@/http/girl/emailNotification.js';
import {sendComplaint} from '@/http/girl/complaints.js';
import { getTwoDaysStatistics, getStatistics, getStatisticFile } from './statistics/statistics';

export default {
  getFavoriteComments,
  getMoreFavoriteComments,
  getFans,
  getMoreFans,
  getLikes,
  getMoreLikes,
  getPosts,
  getMorePosts,
  getProfile,
  subscribe,
  unsubscribe,
  sendComplaint,
  getNotifications,
  getMoreNotifications,
  enableEmailNotification,
  disableEmailNotification,
  getTwoDaysStatistics,
  getStatistics,
  getStatisticFile,
};
