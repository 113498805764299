<template>
  <dialog-component class="create-post" @close="closeCreatePostDialog">
    <template #header-title>Идет загрузка...</template>
    <template #header-icon><i class="i-arrow-left arrow-down" /></template>
    <p class="upload-processing-text">Дождись окончания загрузки файла, а пока можешь полистать ленту</p>
    <ButtonComponent color="transparent" @click="cancelUpload"> Отменить загрузку </ButtonComponent>
  </dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue';
import {mapActions} from 'pinia';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import ButtonComponent from '@/components/ButtonComponent.vue';
import {useCreatePostStore} from '@/stores/createPost.js';

export default {
  name: 'CreatePostLoaderDialog',
  components: {DialogComponent, ButtonComponent},
  methods: {
    ...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
    ...mapActions(useVideoUploadStore, ['cancelUpload']),
  },
};
</script>

<style>
.upload-processing-text {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray);
  margin-bottom: 16px;
}

.arrow-down {
  font-size: 16px;
  transform: rotate(-90deg);
  color: #fff;
}
</style>
