<template>
  <div>
    <header-component />
    <div
      class="content padding-t"
      :class="{'padding-t-l': balanceNotification}"
    >
      <slot />
    </div>
    <nav-menu />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavMenu from '@/components/NavMenu.vue';
import {mapState} from 'pinia';
import {useModalStore} from '@/stores/modal.js';

export default {
  name: 'BalanceWithMenuLayout',
  components: {NavMenu, HeaderComponent},
  computed: {
    ...mapState(useModalStore, ['balanceNotification']),
  },
};
</script>
