import Vue from 'vue';
import App from './App.vue';
import './assets/css/style.scss';
import router from './router';
import http from './http';
import config from './config';
import {createPinia, PiniaVuePlugin} from 'pinia';
import HomeLayout from './layouts/HomeLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';
import BalanceWithMenuLayout from './layouts/BalanceWithMenuLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';
import BalanceOnly from './layouts/BalanceOnly.vue';
import LogoOnly from './layouts/LogoOnly.vue';
import ArrowBackLayout from './layouts/ArrowBackLayout.vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import '@/helpers/validationRules.js';
import * as Sentry from '@sentry/vue';
import lazyImage from '@/directives/lazyImage.js';

const isProductionMode = import.meta.env.PROD;
Vue.directive('lazy', lazyImage);
Vue.component('empty', EmptyLayout);
Vue.component('default', DefaultLayout);
Vue.component('home', HomeLayout);
Vue.component('balanceWithMenu', BalanceWithMenuLayout);
Vue.component('balanceOnly', BalanceOnly);
Vue.component('logoOnly', LogoOnly);
Vue.component('arrowBack', ArrowBackLayout);
Vue.use(VueYandexMetrika, {
  id: 91880150,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

if (isProductionMode) {
  Sentry.init({
    Vue,
    dsn: 'https://20674fea6629d34fd3be9ca49009f377@o204775.ingest.sentry.io/4505724170403840',
    logErrors: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

Vue.use(pinia);

new Vue({
  router,
  http,
  config,
  render: (h) => h(App),
}).$mount('#app');
