<template>
  <div>
    <div class="relative">
      <upload-image-preview v-if="preview" :src="preview" />
      <input-image
        id="photo"
        ref="input"
        @sendEvent="onInput($event)"
        @error="onError($event)"
        :rules="{minDimensions: [500, 217]}"
      />
      <div v-if="preview" class="create-post-image-controls">
        <button v-if="photoAsFile" class="create-post-control" @click="onEdit">
          <i class="i-pen" />
        </button>
        <button class="create-post-control" @click="onReset">
          <i class="i-cross" />
        </button>
      </div>
    </div>
    <ButtonComponent
      v-if="showSubmitButton || hasAttachedPhoto"
      :loading="isSending || isLoadingImage"
      @click="$emit('submit')"
    >
      Опубликовать
    </ButtonComponent>
    <dialog-component v-if="showCropModal" @close="hideCropModal">
      <template #header-title>Редактирование фото</template>
      <div class="cropper-wrapper">
        <cropper
          ref="cropper"
          class="cropper"
          :src="fullBase64"
          :min-width="minStencilWidth"
          :min-height="minStencilHeight"
          :resize-image="false"
          :init-stretcher="initStretcher"
          :stencil-props="{
            minAspectRatio: 0.75,
            maxAspectRatio: 2.3,
          }"
        />
      </div>
      <p class="create-post-crop-description">
        <template> Выбранная область будет показываться в&nbsp;публикации </template>
      </p>
      <ButtonComponent color="transparent" :loading="isLoadingImage" @click="getCroppedImage">
        Сохранить
      </ButtonComponent>
    </dialog-component>
  </div>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue';
import UploadImagePreview from '@/components/UploadImagePreview.vue';
import InputImage from '@/components/forms/InputImage.vue';
import {useCreatePostStore} from '@/stores/createPost.js';
import {mapActions, mapState, mapWritableState} from 'pinia';
import ButtonComponent from '@/components/ButtonComponent.vue';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

const minCropAspectRatio = 0.75;
const minCroppedWidth = 500;

const validationErrorDescriptions = {
  minDimensions: 'Выбери другое фото.',
  size: 'Попробуй уменьшить размер в каком-нибудь приложением.',
  imageExt: 'Переделай в один из этих форматов в каком-нибудь приложении.',
};

export default {
  name: 'CreatePhotoMedia',
  components: {
    DialogComponent,
    InputImage,
    UploadImagePreview,
    ButtonComponent,
    Cropper,
  },
  props: {
    isSending: Boolean,
  },
  data() {
    return {
      showCropModal: false,
      isLoadingImage: false,
      showSubmitButton: false,
      minStencilWidth: null,
      minStencilHeight: null,
    };
  },
  computed: {
    ...mapWritableState(useCreatePostStore, ['croppedBase64', 'fullBase64']),
    ...mapState(useCreatePostStore, ['photoAsFile', 'isCreating', 'post']),
    hasAttachedPhoto() {
      return Boolean(this.fullBase64 || this.croppedBase64 || this.post.photo);
    },
    preview() {
      if (typeof this.post.photo === 'string') {
        return this.post.photo;
      }
      return this.croppedBase64;
    },
  },
  methods: {
    ...mapActions(useCreatePostStore, ['showValidationError', 'resetPhoto']),
    async onInput(event) {
      const {files} = event.target;
      this.showSubmitButton = true;
      this.isLoadingImage = true;

      if (files && files[0]) {
        this.$emit('input', files[0]);
        const reader = new FileReader();

        reader.onload = (e) => {
          this.fullBase64 = e.target.result;
          const image = new Image();

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const {width, height} = ctx.getImageData(0, 0, image.width, image.height);
            const aspectRatio = +(width / height).toFixed(2);
            canvas.width = 500;
            canvas.height = 666;
            const centerY = height / 2;
            const offsetX = image.width * 0.1;
            const croppedWidth = image.width - offsetX * 2;
            const croppedHeight = croppedWidth / minCropAspectRatio;
            const offsetY = centerY - croppedHeight / 2;

            if (aspectRatio < 1) {
              this.minStencilWidth = width * 0.5;
            } else {
              this.minStencilWidth = width * 0.3;
            }
            this.minStencilHeight = this.minStencilWidth * 0.5;

            if (aspectRatio < minCropAspectRatio) {
              ctx.drawImage(image, offsetX, offsetY, croppedWidth, croppedHeight, 0, 0, minCroppedWidth, canvas.height);
              this.croppedBase64 = canvas.toDataURL();
              this.sendCropEvent(croppedWidth, croppedHeight, offsetX, offsetY);
            } else {
              this.croppedBase64 = this.fullBase64;
            }
            this.isLoadingImage = false;
            canvas.remove();
          };

          image.src = this.fullBase64;
        };
        reader.readAsDataURL(files[0]);
      }
    },
    onError(error) {
      const errorKey = Object.keys(error.failedRules)[0];
      this.showValidationError(error.failedRules[errorKey], validationErrorDescriptions[errorKey]);
    },
    onEdit() {
      this.showCropModal = true;
    },
    hideCropModal() {
      this.showCropModal = false;
    },
    onReset() {
      this.croppedBase64 = this.fullBase64 = null;
      this.minStencilWidth = this.minStencilHeight = null;
      this.resetPhoto();
      this.$refs.input.reset();
      this.$emit('crop', null);
      this.showSubmitButton = false;
    },
    getCroppedImage() {
      this.isLoadingImage = true;
      const {canvas} = this.$refs.cropper.getResult();
      const {width, height, top, left} = this.$refs.cropper.coordinates;
      this.croppedBase64 = canvas.toDataURL();
      this.sendCropEvent(width, height, left, top);
      this.hideCropModal();
      this.isLoadingImage = false;
    },
    initStretcher({stretcher, imageSize}) {
      const imageHeight = stretcher.clientWidth * (imageSize.height / imageSize.width);

      stretcher.style.height = `${Math.min(imageHeight, 513, window.innerHeight * 0.45)}px`;
    },
    sendCropEvent(width, height, x, y) {
      this.$emit('crop', {
        width: Math.round(width),
        height: Math.round(height),
        x: Math.round(x) || 1,
        y: Math.round(y) || 1,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cropper {
  &-wrapper {
    max-height: 45vh;
  }
}
</style>
