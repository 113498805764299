<template>
  <validation-provider
    tag="div"
    :name="id"
    ref="provider"
    v-slot="{errors}"
    class="input-image"
    :rules="{
      required: true,
      imageExt: ['png', 'jpeg', 'jpg'],
      aspectRatio: true,
      size: maxImageSize,
      ...rules,
    }"
  >
    <input
      hidden
      :id="id"
      ref="input"
      type="file"
      :capture="capture"
      @change="onChange($event)"
      accept="image/jpeg, image/jpg, image/png"
    />
    <validation-message v-if="errors[0]" :messages="errors" />
  </validation-provider>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import ValidationMessage from '@/components/forms/ValidationMessage.vue';

export default {
  name: 'InputImage',
  components: {ValidationProvider, ValidationMessage},
  props: {
    id: String,
    capture: String,
    rules: Object,
  },
  computed: {
    maxImageSize() {
      return Number(import.meta.env.VITE_MAX_IMG_SIZE_IN_BYTES);
    },
  },
  methods: {
    reset() {
      this.$refs.input.value = '';
      this.$emit('input', '');
      this.$emit('attached', '');
    },
    async onChange(e) {
      this.$emit('loadstart');
      const res = await this.$refs.provider.validate(e);
      if (res.valid) {
        await this.getImageSrc(e);
        this.$emit('sendEvent', e);
        this.$emit('loadend');
      } else {
        this.$emit('error', res);
        this.$emit('loadend');
      }
    },
    getImageSrc(e) {
      return new Promise((resolve) => {
        const files = e.target.files;
        if (files && files.length > 0) {
          const file = files[0];
          this.$emit('input', file);
          const reader = new FileReader();
          reader.onload = () => {
            this.$emit('attached', reader.result);
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
  },
};
</script>
