<template>
  <div>
    <div v-show="hasPreviews">
      <p class="create-post-choose-title">Выбери превью для видео</p>
      <div class="relative">
        <div ref="swiper" class="create-post-preview swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="preview in previews"
              :key="`pr${preview.preview_id}`"
            >
              <img :src="preview.url" class="w-full placeholder-bg" alt="" />
            </div>
          </div>
        </div>
        <div class="post-media-meta">
          {{ currentSlide + 1 }}/{{ totalPreviews }}
        </div>
      </div>
      <ButtonComponent @click="onChoose" color="transparent">
        Выбрать
      </ButtonComponent>
    </div>

    <template v-if="preview_url || videoAsLink">
      <div class="create-post-preview relative">
        <template v-if="videoAsLink">
          <video
            :src="videoAsLink"
            class="w-full"
            playsinline
            autoplay
            muted
            loop
          />
        </template>
        <template v-if="preview_url">
          <upload-image-preview :src="preview_url" />
          <video-processing-notice>
            Видео обрабатывается, но пост уже можно публиковать
          </video-processing-notice>
        </template>

        <div class="create-post-image-controls">
          <button @click="onReset" class="create-post-control">
            <i class="i-cross" />
          </button>
        </div>
      </div>
      <ButtonComponent :loading="isSending" @click="$emit('submit')"
        >Опубликовать</ButtonComponent
      >
    </template>
  </div>
</template>

<script>
import {Swiper} from 'swiper';
import {mapActions, mapState} from 'pinia';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import UploadImagePreview from '@/components/UploadImagePreview.vue';
import {useCreatePostStore} from '@/stores/createPost.js';
import VideoProcessingNotice from '@/components/VideoProcessingNotice.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'CreateVideoMedia',
  components: {VideoProcessingNotice, UploadImagePreview, ButtonComponent},
  props: {
    isSending: Boolean,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    ...mapState(useCreatePostStore, [
      'isCreating',
      'previews',
      'preview_url',
      'videoAsLink',
      'post',
    ]),
    hasPreviews() {
      if (this.previews) {
        return this.previews.length !== 0;
      }
      return true;
    },
    totalPreviews() {
      return this.previews.length;
    },
  },
  methods: {
    ...mapActions(useCreatePostStore, ['choosePreview', 'resetVideo']),
    ...mapActions(useVideoUploadStore, ['deleteVideo']),
    onChoose() {
      this.choosePreview(this.currentSlide);
    },
    onReset() {
      if (this.isCreating) {
        this.deleteVideo(this.post.video_id);
      }
      this.currentSlide = 0;
      this.resetVideo();
    },
  },
  mounted() {
    const swiper = new Swiper(this.$refs.swiper);
    swiper.on('slideChange', (e) => {
      this.currentSlide = e.activeIndex;
    });
  },
};
</script>

<style>
.create-post-choose-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
}

.create-post-preview {
  margin-bottom: 30px;
}
</style>
