<template>
    <div class="text">
      <div class="mb-16 mt-16">Вы получите {{ likesCount }} через&nbsp;<b>{{ formattedDate }} </b></div>
      Подписывайтесь, чтобы получать больше лайков, каждая подписка увеличивает ежедневный бонус на 3 лайка.
    </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useModalStore} from '@/stores/modal.js';
import { useProfileStore } from '@/stores/profile.js';
import plural from '@/helpers/plural.js';
import { subscriptionsParams } from '@/constants';

export default {
  name: 'NotificationBalance',
  data() {
    return {
      timeUntilTomorrow: null,
      dateOptions: {
        timeZone: 'Europe/Moscow',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
    };
  },
  computed: {
    ...mapState(useModalStore, ['balanceNotification']),
    ...mapState(useProfileStore, ['total_subscriptions', 'has_subscription_to_service']),
    formattedDate() {
      return this?.timeUntilTomorrow?.toLocaleString('ru', this.dateOptions);
    },
    likesCount() {
      const hasSubscriptionToService = Number(this.has_subscription_to_service);
      const subscriptionsCount = this.total_subscriptions || subscriptionsParams.defaultSubscriptionsCount;
      const likesCount = (subscriptionsCount + hasSubscriptionToService) * subscriptionsParams.likesForSubscription;
      return `${likesCount} ${plural(likesCount, ['лайк', 'лайка', 'лайков'])}`;
    },
  },
  methods: {
    ...mapActions(useModalStore, ['closeBalanceNotification']),
    calculateTime() {
      let now = new Date();

      let tomorrow = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() + 1
      );

      this.timeUntilTomorrow = new Date(tomorrow - now + 3600000);
    },
  },
  mounted() {
    this.calculateTime();
    window.setInterval(this.calculateTime, 1000);
  },
  beforeDestroy() {
    window.clearInterval(this.calculateTime);
  },
};
</script>

<style scoped>
.text {
  color: var(--text-gray);
  line-height: 20px;
  margin-bottom: 30px;
}
</style>