import {extend} from 'vee-validate';
import {required, email, confirmed, min, max, ext, min_value, max_value, regex} from 'vee-validate/dist/rules';
import digitsOfNumber from '@/helpers/digitsOfNumber.js';

function getImageSizes(file) {
  const URL = window.URL || window.webkitURL;
  return new Promise(function (resolve) {
    const image = new Image();
    image.onerror = function () {
      return resolve(false);
    };
    image.onload = function () {
      return resolve(image);
    };
    image.src = URL.createObjectURL(new Blob(file));
  });
}

const requiredTexts = {
  email: 'Введите адрес эл. почты',
  password: 'Введите пароль',
  passwordConfirmation: 'Пароль необходимо повторить',
  gesture: 'Загрузите фото с жестом',
  photoWithPassport: 'Загрузите фото с паспортом',
  nickname: 'Введите имя в приложении',
  account_number: 'Введите номер карты',
  secret_number: 'Введите номер карты',
};

const emailTexts = {
  email: 'Некорректный адрес эл. почты',
};

const regexTexts = {
  account_number: 'Некорректный номер',
  secret_number: 'Некорректный номер',
};

const passwordTexts = {
  password: 'Пароль должен состоять из цифр и латинских букв',
};

const confirmedTexts = {
  passwordConfirmation: 'Пароли не совпадают',
};

const minTexts = {
  nickname: (length) => `Никнейм должен содержать минимум ${length} символа`,
  about_yourself: (length) => `Поле о себе должно содержать минимум ${length} символов`,
};

const maxTexts = {
  nickname: (length) => `Никнейм должен содержать максимум ${length} символа`,
  about_yourself: (length) => `Поле о себе должно содержать максимум ${length} символа`,
  cardName: (length) => `Название карты должно содержать максимум ${length} символа`,
};

const minValueTexts = {
  amount: (value) => `Минимальная сумма — ${digitsOfNumber(value)} ₽`,
};

const maxValueTexts = {
  amount: (value) => `Максимальная сумма — ${digitsOfNumber(value)} ₽`,
};

extend('required', {
  ...required,
  message: (field) => requiredTexts[field],
});

extend('regex', {
  ...regex,
  message: (field) => regexTexts[field],
});

extend('email', {
  ...email,
  message: (field) => emailTexts[field],
});

extend('password', {
  validate: (value) => {
    return /^[a-zA-Z0-9]+$/.test(value);
  },
  message: passwordTexts.password,
});

extend('confirmed', {
  ...confirmed,
  message: (field) => confirmedTexts[field],
});

extend('minDimensions', {
  validate: async (file, [width, height]) => {
    const image = await getImageSizes(file);
    return image.width >= width && image.height >= height;
  },
  message: (file, params) => {
    const width = params[0];
    const height = params[1];
    return `Фото слишком маленькое. Минимальный размер: ${width}px в ширину и ${height}px в высоту.`;
  },
});

extend('noOnlySpaces', {
  validate: (string) => {
    return /.*\S.*/gm.test(string);
  },
  message: 'Введите какой-нибудь символ',
});

extend('aspectRatio', {
  validate: async (file) => {
    const {width, height} = await getImageSizes(file);
    return Math.max(width, height) / Math.min(width, height) < 3;
  },
  message: 'Нельзя использовать фотографии, у которых одна из сторон в несколько раз превышает другую',
});

extend('size', {
  validate: async (file, size) => {
    const fileSize = file[0].size;
    return fileSize < size;
  },
  message: () => {
    const maxSize = Number(import.meta.env.VITE_MAX_IMG_SIZE_IN_BYTES);
    return `Фото слишком большое. Максимальный размер — ${maxSize / 1000000} Мб.`;
  },
});

extend('min', {
  ...min,
  message: (field, {length}) => {
    return minTexts[field](length);
  },
});

extend('max', {
  ...max,
  message: (field, {length}) => {
    return maxTexts[field](length);
  },
});

extend('min_value', {
  ...min_value,
  message: (field, {min}) => {
    return minValueTexts[field](min);
  },
});

extend('max_value', {
  ...max_value,
  message: (field, {max}) => {
    return maxValueTexts[field](max);
  },
});

extend('imageExt', {
  ...ext,
  message: () => 'Неизвестный формат файла. Допустимые форматы: JPEG/JPG, PNG, HEIC, HEIF.',
});
