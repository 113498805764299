<template>
  <div class="menu flex row space">
    <div class="menu-item" style="order: 2" v-if="this.$auth.check('girl')">
      <CreateButton :type="createButtonState" :percentage="progress" @openCreatePostDialog="openCreatePostDialog" />
    </div>
    <div
      v-for="(link, i) in isConsumer ? consumerLinks : girlLinks"
      :key="link.icon"
      :style="{order: i}"
      class="menu-item-wrap"
      @click="resetFilterAndScroll(link)"
    >
      <router-link class="menu-item" v-bind="link">
        <div class="icon" :class="[[link.icon], {indicator: link.icon === 'notifications' && hasNotifications}]" />
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useProfileStore} from '@/stores/profile.js';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import {useHomeStore} from '@/stores/home.js';
import CreateButton from './CreateButton.vue';
import {createButtonTypes} from '@/constants.js';
import {useCreatePostStore} from '@/stores/createPost.js';

const commonRoutes = [
  {
    to: {name: 'home'},
    icon: 'home',
    exactActiveClass: 'active',
  },
  {
    to: {name: 'search'},
    icon: 'search',
    activeClass: 'active',
  },
];

export default {
  name: 'NavMenu',
  components: {CreateButton},
  computed: {
    isConsumer() {
      return this.$auth.check('consumer');
    },
    ...mapState(useVideoUploadStore, ['progress']),
    ...mapState(useGirlProfileStore, {girlNotifications: 'notifications'}),
    ...mapState(useProfileStore, {
      consumerId: 'id',
      consumerNotifications: 'notifications',
    }),
    ...mapState(useGirlProfileStore, {girlId: 'id'}),
    hasNotifications() {
      return this.girlNotifications > 0 || this.consumerNotifications > 0;
    },
    consumerLinks() {
      return [
        ...commonRoutes,
        {
          to: {name: 'consumer-notifications'},
          icon: 'notifications',
          activeClass: 'active',
        },
        {
          to: {name: 'consumer-profile', params: {id: this.consumerId}},
          icon: 'profile',
          activeClass: 'active',
        },
      ];
    },
    girlLinks() {
      return [
        ...commonRoutes,
        {
          to: {name: 'author-notifications'},
          icon: 'notifications',
          activeClass: 'active',
        },
        {
          to: {name: 'author-profile', params: {id: this.girlId}},
          icon: 'profile',
          activeClass: 'active',
        },
      ];
    },
    createButtonState() {
      if (this.progress === 0) {
        return createButtonTypes.default;
      }
      if (this.progress === 100) {
        return createButtonTypes.ready;
      }
      return createButtonTypes.loading;
    },
  },
  methods: {
    ...mapActions(useCreatePostStore, ['openCreatePostDialog']),
    ...mapActions(useHomeStore, ['resetFilter']),
    resetFilterAndScroll(link) {
      if (link.to.name === 'home') {
        this.resetFilter();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>
