import {getPostAudienceList} from '@/http/girl/createPost/postAudience.js';
import {
  createPostWithPhoto,
  createPostWithVideo,
} from '@/http/girl/createPost/createPost.js';
import {deleteVideo} from '@/http/girl/createPost/deleteVideo.js';
import {
  editPostWithPhoto,
  editPostWithVideo,
} from '@/http/girl/createPost/editPost.js';

export default {
  getPostAudienceList,
  createPostWithPhoto,
  createPostWithVideo,
  editPostWithPhoto,
  editPostWithVideo,
  deleteVideo,
};
