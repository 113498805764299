export default new IntersectionObserver(
  (entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        entry.target.load();
      }
    });
  },
  {root: null, rootMargin: '100%'}
);
