<template>
  <div class="fixed-top">
    <div class="header flex row space container">
      <slot>
        <button @click="$router.back()" class="back" />
      </slot>
      <div v-if="title" class="title">{{ title }}</div>
      <like-balance v-if="isConsumer" />
<!--      <money-balance v-else />-->
    </div>
  </div>
</template>

<script>
import LikeBalance from '@/components/LikeBalance.vue';
import MoneyBalance from './MoneyBalance.vue';

export default {
  name: 'HeaderComponent',
  components: {LikeBalance, MoneyBalance},
  computed: {
    title() {
      return this.$route.meta.title;
    },
    isConsumer() {
      return this.$auth.check('consumer');
    },
  },
};
</script>
