import {http} from '@/http/index.js';

async function searchPostsByDescription(search) {
  return http
    .get('/posts/search', {
      params: {
        search,
      },
    })
    .then((response) => response.data);
}

export {searchPostsByDescription};
