<template>
  <div class="post-like-counter">
    <span
      class="like-icon lg"
      :class="{active: amount}"
      @click.prevent="onLike"
    />
    <span class="text-medium">{{ amount }}</span>
  </div>
</template>

<script>
import {useProfileStore} from '@/stores/profile.js';
import {mapActions} from 'pinia';
import {useModalStore} from '@/stores/modal.js';

export default {
  name: 'PostLike',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    postId: Number,
  },
  methods: {
    ...mapActions(useProfileStore, ['setLike']),
    ...mapActions(useModalStore, ['showBalanceNotification']),
    async onLike() {
      try {
        await this.setLike(this.postId);
      } catch (error) {
        this.showBalanceNotification();
      }
    },
  },
};
</script>
