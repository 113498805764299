<template>
  <div>
    <button
      @click="onClick"
      :class="[btnClass, {invert: isSubscribed}]"
      :disabled="isWaitingSubscriptionResult"
    >
      <template v-if="isWaitingSubscriptionResult">
        <div class="i-spinner post-subscribe-spinner rotating" />
      </template>
      <template v-else>
        {{ isSubscribed ? 'Вы подписаны' : 'Подписаться' }}
      </template>
    </button>
    <confirmation-dialog
      v-if="dialogVisible"
      btn-accept-text="Да, отписаться"
      btn-decline-text="Нет, передумал"
      @accept="onUnsubscribe"
      @decline="hideDialog"
      @close="hideDialog"
    >
      <template #title>Вы хотите отписаться?</template>
      Тогда вы не сможете видеть новые посты модели и не будете получать
      взаимность
    </confirmation-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useGirlStore} from '@/stores/girl.js';
import {useHomeStore} from '@/stores/home.js';
import {useConsumerStore} from '@/stores/consumer.js';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import {useSearchStore} from '@/stores/search.js';
import {useProfileStore} from '@/stores/profile.js';

export default {
  name: 'SubscribeButton',
  components: {ConfirmationDialog},
  props: {
    id: Number,
    isSubscribed: Boolean,
    isWaitingSubscriptionResult: Boolean,
    btnClass: String,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(useProfileStore, {consumerId: 'id'}),
    subscribeLink() {
      const host = import.meta.env.VITE_API_URL;
      return `${host}consumers/${this.consumerId}/girls/${this.id}/subscribe`;
    },
  },
  methods: {
    ...mapActions(useProfileStore, ['getFollowingGirls']),
    ...mapActions(useGirlStore, {
      removeBlurFromGirlPosts: 'removeBlurFromPosts',
      applyBlurToGirlPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
      turnOnWaitingSubscriptionForGirlPost: 'turnOnWaitingSubscription',
      subscribe: 'subscribe',
      unsubscribe: 'unsubscribe',
    }),
    ...mapActions(useHomeStore, {
      resetFilter: 'resetFilter',
      removeBlurFromHomePosts: 'removeBlurFromPosts',
      applyBlurToHomePostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
      turnOnWaitingSubscriptionForHomePost: 'turnOnWaitingSubscription',
    }),
    ...mapActions(useConsumerStore, {
      removeBlurFromConsumerPosts: 'removeBlurFromPosts',
      applyBlurToConsumerPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
      turnOnWaitingSubscriptionForConsumerPost: 'turnOnWaitingSubscription',
    }),
    ...mapActions(useSearchStore, {
      removeBlurFromSearchPosts: 'removeBlurFromPosts',
      applyBlurToSearchPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
      turnOnWaitingSubscriptionForSearchPost: 'turnOnWaitingSubscription',
    }),
    showDialog() {
      this.dialogVisible = true;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    onClick() {
      if (this.isSubscribed) {
        this.showDialog();
        return;
      }
      window.location.href = this.subscribeLink;
    },
    async onUnsubscribe() {
      await this.unsubscribe(this.id);
      this.applyBlurToGirlPostsOnlyForFans(this.id);
      this.applyBlurToHomePostsOnlyForFans(this.id);
      this.applyBlurToConsumerPostsOnlyForFans(this.id);
      this.applyBlurToSearchPostsOnlyForFans(this.id);
      this.turnOnWaitingSubscriptionForGirlPost(this.id);
      this.turnOnWaitingSubscriptionForHomePost(this.id);
      this.turnOnWaitingSubscriptionForConsumerPost(this.id);
      this.turnOnWaitingSubscriptionForSearchPost(this.id);
      await this.getFollowingGirls();
      await this.resetFilter(this.id);
      this.hideDialog();
    },
  },
};
</script>
