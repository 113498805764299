import {http} from '@/http/index.js';

async function getPosts(girlId) {
  return http.get(`/girls/${girlId}/posts`).then((response) => response.data);
}

async function getMorePosts(url) {
  return http.get(url).then((response) => response.data);
}

export {getPosts, getMorePosts};
