<template>
  <div class="post-photo placeholder-bg flex">
    <img
      alt=""
      src="#"
      v-lazy="photos[0].url"
      :height="photos[0].height"
      :width="photos[0].width"
    />
  </div>
</template>

<script>
export default {
  name: 'PostMediaImage',
  props: {
    photos: Array,
  },
};
</script>
