import {defineStore} from 'pinia';
import girlApi from '@/http/girl';
import posts, {getMoreData} from './helper.js';

export const useGirlStore = defineStore('girl', {
  state: () => ({
    girlId: null,
    profile: null,
    posts: null,
    favoriteComments: null,
    fans: null,
    likes: null,
    isLoading: false,
  }),
  getters: {
    postsHasLoaded: (state) => state.posts !== null,
    favoriteCommentsHasLoaded: (state) => state.favoriteComments !== null,
  },
  actions: {
    updateLikes(girl, post) {
      if (this.profile) {
        this.profile.total_likes = girl.total_likes;
      }
      posts.findPostAndUpdateLikesValue(this.posts, post);
    },
    async getLikes(girlId) {
      this.isLoading = true;
      this.likes = await girlApi.getLikes(girlId);
      this.isLoading = false;
    },
    async getMoreLikes() {
      await getMoreData(this.likes, girlApi.getMoreLikes);
    },
    async getFans(girlId) {
      this.isLoading = true;
      this.fans = null;
      const result = await girlApi.getFans(girlId);
      if (result) {
        this.fans = result;
      }
      this.isLoading = false;
    },
    async getMoreFans() {
      await getMoreData(this.fans, girlApi.getMoreFans);
    },
    async subscribe(girlId) {
      this.isLoading = true;
      await girlApi.subscribe(girlId);
      this.isLoading = false;
    },
    async unsubscribe(girlId) {
      this.isLoading = true;
      try {
        await girlApi.unsubscribe(girlId);
        this.updateSubscriptionState(girlId);
      } finally {
        this.isLoading = false;
      }
    },
    updateSubscriptionState() {
      if (this.profile) {
        this.profile.acting_consumer_is_subscribed =
          !this.profile.acting_consumer_is_subscribed;
        this.profile.waiting_subscription_result =
          !this.profile.waiting_subscription_result;
        this.profile.total_fans -= 1;
      }
    },
    applyBlurToPostsOnlyForFans(girlId) {
      if (this.posts) {
        posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId);
      }
    },
    removeBlurFromPosts(girlId) {
      if (this.posts) {
        posts.medialContentVisibleForAllConsumers(this.posts.data, girlId);
      }
    },
    turnOnWaitingSubscription(girlId) {
      if (this.posts) {
        posts.turnOnWaitingSubscription(this.posts.data, girlId);
      }
    },
    async getGirlProfile(girlId) {
      this.girlId = girlId;
      this.profile = null;
      this.isLoading = true;
      this.profile = await girlApi.getProfile(girlId);
      this.isLoading = false;
    },
    async getPosts(girlId) {
      this.isLoading = true;
      this.posts = await girlApi.getPosts(girlId);
      this.isLoading = false;
    },
    async getMorePosts() {
      await getMoreData(this.posts, girlApi.getMorePosts);
    },
    async getFavoriteComments(girlId) {
      this.favoriteComments = null;
      this.isLoading = true;
      this.favoriteComments = await girlApi.getFavoriteComments(girlId);
      this.isLoading = false;
    },
    async getMoreFavoriteComments() {
      await getMoreData(this.favoriteComments, girlApi.getMoreFavoriteComments);
    },
    removePostFromList(postId) {
      posts.removePostFromList(this.posts, postId);
    },
    async sendComplaint(girlId) {
      await girlApi.sendComplaint(girlId);
    },
  },
});
