export const prefix = 'scroll-';

function saveScroll(name, position) {
  window.localStorage.setItem(prefix + name, position);
}

function getScroll(name) {
  if (window.localStorage[prefix + name]) {
    return Number(window.localStorage.getItem(prefix + name));
  }
}

export default {
  saveScroll,
  getScroll,
};
