import Home from '@/views/HomeView.vue';

const routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: {
      auth: true,
      layout: 'home',
    },
  },
  {
    name: 'post',
    path: '/post/:id',
    component: () => import('@/views/PostView.vue'),
    meta: {
      auth: true,
      layout: 'balanceOnly',
    },
  },
  {
    path: '/search',
    component: () => import('@/views/Search/Index.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        name: 'search',
        path: '',
        component: () => import('@/views/Search/SearchView.vue'),
      },
      {
        name: 'search-posts',
        path: 'posts',
        component: () => import('@/views/Search/PostsView.vue'),
        meta: {
          layout: 'balanceWithMenu',
        },
      },
      {
        name: 'recommended-posts',
        path: 'recommended-posts',
        component: () => import('@/views/Search/RecommendedPostsView.vue'),
        meta: {
          layout: 'balanceWithMenu',
          title: 'Рекомендации',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      auth: false,
      layout: 'empty',
    },
  },
  {
    path: '/post/:id/likes',
    name: 'post-likes',
    component: () => import('@/views/ConsumersWithLikesView.vue'),
    meta: {
      auth: true,
      layout: 'arrowBack',
      title: 'Нравится',
    },
  },
  {
    path: '/sign-in-as-test-user',
    name: 'sign-in-as-test-user',
    component: () => import('@/views/SignInAsTestUser.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
  {
    path: '/eula',
    name: 'eula',
    component: () => import('@/views/EulaView.vue'),
    meta: {
      layout: 'arrowBack',
      title: 'Пользовательское соглашение',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PrivacyPolicyView.vue'),
    meta: {
      layout: 'arrowBack',
      title: 'Политика конфиденциальности',
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/TermsOfServiceView.vue'),
    meta: {
      layout: 'arrowBack',
      title: 'Соглашение с автором',
    },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/LegalView.vue'),
    meta: {
      layout: 'arrowBack',
      title: 'Правила услуги',
    },
  },
  {
    path: '/',
    name: 'service-unavailable',
    component: () => import('@/views/ErrorServiceUnavailable.vue'),
    meta: {
      layout: 'logoOnly',
    },
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('@/views/Girl/StartView.vue'),
    meta: {
      layout: 'logoOnly',
    },
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    props: true,
    meta: {
      layout: 'logoOnly',
    },
  },
];

export default routes;
