<template>
  <div class="content p-0">
    <div class="header flex row space container">
      <button @click="$router.back()" class="back" />
      <div v-if="title" class="title">{{ title }}</div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ArrowBackLayout',
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
};
</script>
