<template>
  <div class="h-full">
    <component class="h-full" :is="layout">
      <router-view />
    </component>
    <network-error-notification v-if="networkErrorNotification" />
    <dialog-component v-if="balanceNotification" @close="closeBalanceNotification">
      <template #header-title>Упс... кончились лайки</template>
      <notification-balance />
    </dialog-component>
    <template v-if="$auth.check('girl')">
      <create-post-dialog @close="closeCreatePostDialog" v-if="showCreatePostDialog && !isVideoUploading" />
      <create-post-loader-dialog v-if="showCreatePostDialog && isVideoUploading" />
      <girl-verification-dialog v-if="showVerificationDialog" @close="closeVerificationDialog" />
    </template>
    <the-app-loader />
  </div>
</template>

<script>
import NetworkErrorNotification from '@/components/NetworkErrorNotification.vue';
import CreatePostDialog from '@/components/dialogs/CreatePostDialog.vue';
import NotificationBalance from '@/components/NotificationBalance.vue';
import {mapActions, mapState} from 'pinia';
import {useModalStore} from '@/stores/modal.js';
import DialogComponent from '@/components/dialogs/DialogComponent.vue';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import CreatePostLoaderDialog from '@/components/dialogs/CreatePostLoaderDialog.vue';
import GirlVerificationDialog from '@/components/dialogs/GirlVerificationDialog.vue';
import TheAppLoader from '@/components/TheAppLoader.vue';
import {useCreatePostStore} from '@/stores/createPost.js';

export default {
  components: {
    TheAppLoader,
    GirlVerificationDialog,
    CreatePostLoaderDialog,
    DialogComponent,
    CreatePostDialog,
    NetworkErrorNotification,
    NotificationBalance,
  },
  computed: {
    ...mapState(useVideoUploadStore, ['isVideoUploading']),
    ...mapState(useModalStore, ['networkErrorNotification', 'showVerificationDialog', 'balanceNotification']),
    ...mapState(useCreatePostStore, ['showCreatePostDialog']),
    layout() {
      return this.$route.meta.layout || 'default';
    },
  },
  methods: {
    ...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
    ...mapActions(useModalStore, ['closeVerificationDialog', 'closeBalanceNotification']),
  },
};
</script>
