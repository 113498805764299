<template>
  <div class="content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>
