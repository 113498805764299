import {http} from '@/http/index.js';

async function editPostWithPhoto(postId, data) {
  return http.post(`/girls/posts/photo/${postId}`, data);
}

async function editPostWithVideo(postId, data) {
  return http.post(`girls/posts/video/${postId}`, data);
}

export {editPostWithPhoto, editPostWithVideo};
