import {http} from '@/http/index.js';

async function enableSmsNotification() {
  return http.put('/consumer/sms-notifications', {
    sms_notification_on: true,
  });
}

async function disableSmsNotification() {
  return http.put('/consumer/sms-notifications', {
    sms_notification_on: false,
  });
}

export {enableSmsNotification, disableSmsNotification};
