export default new IntersectionObserver(
  (entries) => {
    entries.forEach(async (entry) => {
      const video = entry.target;
      const playPromise = video.play;
      async function reload() {
        await video.load();
        if (entry.isIntersecting) {
          video.play();
        }
      }

      if (playPromise !== undefined) {
        try {
          if (entry.isIntersecting) {
            video.play();
          }
          if (!entry.isIntersecting) {
            video.pause();
          }
          await playPromise;
          if (video.error) {
            await reload();
          }
        } catch (error) {
          await reload();
        }
      }
    });
  },
  {threshold: 0.5}
);
