<template>
  <validation-provider
    tag="div"
    class="form-control"
    v-slot="{errors, invalid, validated}"
    :name="id"
    :rules="rules"
  >
    <label class="form-label" for="id">{{ label }}</label>
    <div class="form-field-wrap">
      <textarea
        ref="textarea"
        v-bind="$attrs"
        class="form-field h-full"
        :class="{invalid: invalid && validated}"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <validation-message :messages="errors" />
  </validation-provider>
</template>

<script>
import ValidationMessage from '@/components/forms/ValidationMessage.vue';
import {ValidationProvider} from 'vee-validate';
import isMobile from 'ismobilejs';

export default {
  name: 'TextareaComponent',
  inheritAttrs: false,
  components: {ValidationProvider, ValidationMessage},
  props: {
    value: String,
    id: {
      type: String,
      required: true,
    },
    label: String,
    rules: Object,
  },
  mounted() {
    const { textarea } = this.$refs;
    const gap = isMobile().apple.device ? 18 : 2;
    textarea.style.height = `${textarea.scrollHeight + gap}px`;
  },
};
</script>
