function mediaContentVisibleOnlyForFans(posts, girlId) {
  posts
    .filter(({author}) => author.id === girlId)
    .map(({author}) => {
      author.has_in_fans_current_consumer = false;
    });
}

function medialContentVisibleForAllConsumers(posts, girlId) {
  posts
    .filter(({author}) => author.id === girlId)
    .map(({author}) => {
      author.has_in_fans_current_consumer = true;
    });
}

function turnOnWaitingSubscription(posts, girlId) {
  posts
    .filter(({author}) => author.id === girlId)
    .map(({author}) => {
      author.waiting_subscription_result = true;
    });
}

function findPostAndUpdateLikesValue(posts, post) {
  if (posts) {
    const likedPost = posts.data.find((el) => el.id === post.id);
    if (likedPost) {
      likedPost.total_likes = post.likes;
      likedPost.total_consumer_likes++;
    }
  }
}

function removePostFromList(postsList, postId) {
  if (postsList) {
    const index = postsList.data.findIndex((post) => post.id === postId);
    if (index !== -1) {
      postsList.data.splice(index, 1);
    }
  }
}

function arrayUnique(array, field = null) {
  if (field) {
    if (!array[0][field]) {
      return array;
    }
    return array.filter(
      (n, i, a) => n === a.find((m) => m[field] === n[field])
    );
  }
  return Array.from(new Set(array));
}

async function getMoreData(content, loadFunction) {
  const link = content.links.next;
  if (!link) {
    return;
  }
  try {
    const {data, links, meta} = await loadFunction(link);
    content.data = arrayUnique([...content.data, ...data], 'id');
    content.links = links;
    content.meta = meta;
  } catch (e) {
    return e;
  }
}

export {getMoreData};

export default {
  mediaContentVisibleOnlyForFans,
  medialContentVisibleForAllConsumers,
  findPostAndUpdateLikesValue,
  turnOnWaitingSubscription,
  removePostFromList,
};
