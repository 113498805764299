import Vue from 'vue';
import VueRouter from 'vue-router';
import commonRoutes from '@/router/commonRoutes.js';
import girlAuthRoutes from '@/router/girlAuthRoutes.js';
import girlRoutes from '@/router/girlRoutes.js';
import consumerRoutes from '@/router/consumerRoutes.js';
import scrollKeeper from '@/helpers/scrollKeeper.js';
import {useProfileStore} from '@/stores/profile.js';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import {useModalStore} from '@/stores/modal.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...commonRoutes,
    ...girlAuthRoutes,
    ...girlRoutes,
    ...consumerRoutes,
  ],
  scrollBehavior(to) {
    const scrollPosition = {x: 0, y: scrollKeeper.getScroll(to.name)};
    if (to.hash) {
      return {selector: to.hash};
    }
    return scrollPosition;
  },
});

const exceptionRoutes = [
  'eula',
  'subswait',
  'privacy-policy',
  'author-start-waiting-subscription',
  'error-subscription-failed-only-one-girl',
  'error-subscription-waiting-only-one-girl',
];

router.beforeEach(async (to, from, next) => {
  useModalStore().showAppLoader();
  if (exceptionRoutes.includes(to.name)) {
    next();
    return;
  }
  if (Vue.auth.token() && Vue.auth.remember()) {
    const userData = JSON.parse(Vue.auth.remember());
    Vue.auth.user(userData);
    if (Vue.auth.check('consumer')) {
      const consumerProfileStore = useProfileStore();
      if (!consumerProfileStore.isProfileLoaded) {
        consumerProfileStore.getProfile();
      }
    }
    if (Vue.auth.check('girl')) {
      const girlProfileStore = useGirlProfileStore();
      if (!girlProfileStore.isProfileLoaded) {
        await girlProfileStore.getProfile();
      }
      if (!girlProfileStore.nickname) {
        if (to.name === 'author-profile-edit') {
          next();
          return;
        }
        next({name: 'author-profile-edit'});
        return;
      }
    }
  }
  next();
});

router.afterEach(() => {
  useModalStore().hideAppLoader();
});

export default router;
