<template functional>
  <div class="history-item" v-bind="data.attrs">
    <div class="image-container">
      <div class="image placeholder" />
    </div>
    <div class="history-item-name">
      <div class="placeholder w-full" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderFilterCard',
};
</script>
