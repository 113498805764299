import {loadAvatar} from '@/http/girl/profile/loadAvatar.js';
import {loadCover} from '@/http/girl/profile/loadCover.js';
import {updateProfile} from '@/http/girl/profile/updateProfile.js';
import {getGirlProfile} from '@/http/girl/profile/girls.js';
import {getProfile} from '@/http/girl/profile/profile.js';

export default {
  loadAvatar,
  loadCover,
  updateProfile,
  getGirlProfile,
  getProfile,
};
