import {defineStore} from 'pinia';

export const useModalStore = defineStore('modal', {
  state: () => ({
    appLoaderVisibility: false,
    showVerificationDialog: false,
    balanceNotification: false,
    networkErrorNotification: false,
  }),
  actions: {
    showAppLoader() {
      this.appLoaderVisibility = true;
    },
    hideAppLoader() {
      this.appLoaderVisibility = false;
    },
    openVerificationDialog() {
      this.showVerificationDialog = true;
    },
    closeVerificationDialog() {
      this.showVerificationDialog = false;
    },
    showBalanceNotification() {
      this.balanceNotification = true;
    },
    closeBalanceNotification() {
      this.balanceNotification = false;
    },
    showNetworkError() {
      this.networkErrorNotification = true;
      setTimeout(this.hideNetworkError, 3000);
    },
    hideNetworkError() {
      this.networkErrorNotification = false;
    },
  },
});
