<template>
  <div ref="scrollComponent">
    <slot />
    <div v-if="isLoading" class="pagination-loader">
      <i class="i-spinner rotating" />
    </div>
    <div v-if="isNetworkError" class="container">
      <ButtonComponent
        color="transparent"
        @click="retryLoad"
        :loading="isLoading"
      >
        Перезагрузить
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import scrollKeeper, {prefix} from '@/helpers/scrollKeeper.js';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'InfiniteScroll',
  components: {
    Loader,
    ButtonComponent,
  },
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    elementsToTheEnd: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      elementHeight: null,
      isNetworkError: false,
      isLoading: false,
      forceToTopRoutes: ['author-profile'],
    };
  },
  methods: {
    getElementHeight() {
      const scrollComponent = this.$refs.scrollComponent;
      const firstChild = scrollComponent.children[0];
      if (firstChild) {
        this.elementHeight = firstChild.clientHeight;
      }
    },
    handleScroll() {
      if (!this.elementHeight) this.getElementHeight();
      let scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );

      const scroll = window.scrollY;
      const pixelsLeft = scrollHeight - window.innerHeight - scroll;

      scrollKeeper.saveScroll(this.$route.name, scroll);

      if (pixelsLeft < this.elementHeight * this.elementsToTheEnd) {
        this.loadMoreData();
      }
    },
    async loadMoreData() {
      if (!this.isLoading && !this.isNetworkError) {
        this.isLoading = true;
        try {
          await this.loadFunction();
          this.isNetworkError = false;
        } catch (error) {
          if (error.code === 'ERR_NETWORK') {
            this.isNetworkError = true;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    retryLoad() {
      this.isNetworkError = false;
      this.loadMoreData();
    },
  },
  mounted() {
    if (
      !window.localStorage.getItem(prefix + this.$route.name) ||
      this.forceToTopRoutes.includes(this.$route.name)
    ) {
      scrollKeeper.saveScroll(this.$route.name, '0');
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.pagination-loader {
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 48px;
  color: #6a6a78;
  width: 100%;
}
</style>
