<template functional>
  <img
    class="upload-image-preview"
    v-show="props.src"
    :src="props.src"
    alt=""
  />
</template>

<script>
export default {
  name: 'UploadImagePreview',
  props: {
    src: String,
  },
};
</script>

<style>
.upload-image-preview {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
}
</style>
