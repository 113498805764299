<template>
  <div class="logo" @click="resetFilterAndScroll">
    <img src="../assets/images/logo.png" alt="" />
  </div>
</template>

<script>
import {mapActions} from 'pinia';
import {useHomeStore} from '@/stores/home.js';

export default {
  name: 'HeaderLogo',
  methods: {
    ...mapActions(useHomeStore, ['resetFilter']),
    resetFilterAndScroll() {
      this.resetFilter();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
