import {defineStore} from 'pinia';
import postsApi from '@/http/posts';
import girlApi from '@/http/girl';
import posts, {getMoreData} from './helper.js';

export const useHomeStore = defineStore('home', {
  state: () => ({
    posts: null,
    isLoading: false,
    filter: {
      currentGirl: null,
    },
  }),
  getters: {
    postsHasLoaded: (state) => state.posts !== null,
  },
  actions: {
    updateLikes(post) {
      posts.findPostAndUpdateLikesValue(this.posts, post);
    },
    async getPosts() {
      this.isLoading = true;
      this.posts = null;
      this.filter.currentGirl = null;
      this.posts = await postsApi.getPosts();
      this.isLoading = false;
    },
    async getMorePosts() {
      await getMoreData(this.posts, postsApi.getMorePosts);
    },
    applyBlurToPostsOnlyForFans(girlId) {
      if (this.posts) {
        posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId);
      }
    },
    removeBlurFromPosts(girlId) {
      if (this.posts) {
        posts.medialContentVisibleForAllConsumers(this.posts.data, girlId);
      }
    },
    turnOnWaitingSubscription(girlId) {
      if (this.posts) {
        posts.turnOnWaitingSubscription(this.posts.data, girlId);
      }
    },
    async setFilter(girlId) {
      this.posts = null;
      this.isLoading = true;
      if (this.filter.currentGirl === girlId) {
        this.filter.currentGirl = null;
        await this.getPosts();
      } else {
        this.filter.currentGirl = girlId;
        this.posts = await girlApi.getPosts(girlId);
      }
      this.isLoading = false;
    },
    async resetFilter() {
      this.filter.currentGirl = null;
      await this.getPosts();
    },
    removePostFromList(postId) {
      posts.removePostFromList(this.posts, postId);
    },
  },
});
