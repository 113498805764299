import {http} from '@/http/index.js';

async function createPostWithPhoto(data) {
  return http.post(`/girls/posts/photo`, data);
}

async function createPostWithVideo(data) {
  return http.post(`/girls/posts/video`, data);
}

export {createPostWithPhoto, createPostWithVideo};
