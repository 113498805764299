import {http} from '@/http/index.js';

async function getTwoDaysStatistics() {
  return http.get('/girl/two-days-statistics').then((response) => {
    return response.data;
  });
}

async function getStatistics(datesRange) {
  const params = {
    date_range: datesRange,
  };
  return http.get('/girl/statistics', { params }).then((response) => {
    return response.data.data;
  });
}

async function getStatisticFile(datesRange) {
  const params = {
    date_range: datesRange,
  };
  return http.get('/girl/export-statistics-history', { params, responseType: 'blob' }).then((response) => {
    return response.data;
  });
}

export { getTwoDaysStatistics, getStatistics, getStatisticFile };