<template>
  <dialog-component class="create-post" @close="onClose">
    <template #header-title>
      {{ isCreating ? 'Новая публикация' : 'Редактирование публикации' }}
    </template>
    <div class="create-post-top">
      <div class="flex align">
        <avatar-circle :image-path="avatar" />
        <span class="create-post-nickname">{{ nickname }}</span>
      </div>
      <button v-if="hasAudienceList" class="create-post-top-btn rounded-button" @click="openAudienceModal">
        {{ audienceList[post.audience] }}
      </button>
    </div>
    <form-component ref="form">
      <textarea-component
        id="description"
        v-model="post.description"
        class="create-post-description"
        placeholder="Опиши свое настроение, расскажи небольшую историю или задай вопрос аудитории 😉 Упоминай @никнейм, чтобы наградить кого-нибудь и не забудь #теги, чтобы быть на виду."
      >
      </textarea-component>
    </form-component>
    <create-photo-media
      ref="photo"
      :is-sending="isSending"
      v-model="post.photo"
      @crop="onCrop($event)"
      @submit="onSubmitPostWithPhoto"
    />
    <create-video-media :is-sending="isSending" @submit="onSubmitPostWithVideo" />
    <template v-if="!hasAttachedMedia">
      <ButtonComponent tag="label" for="photo" color="transparent" class-name="mb-8">Добавить фото</ButtonComponent>
      <upload-video-button v-model="post.video_id" />
    </template>
    <dialog-component v-show="showAudienceModal" @close="closeAudienceModal">
      <template #header-title>Выберете статус поста</template>
      <label class="create-post-radio" v-for="(label, value) in audienceList" :key="value">
        <input hidden type="radio" :value="value" name="post-visibility" v-model="post.audience" />
        <span>{{ label }}</span>
      </label>
    </dialog-component>
    <dialog-component v-show="showValidationDialog" @close="hideValidationDialog">
      <template #header-title>Не подходит</template>
      <div class="create-post-error">
        <p>{{ validationError.message }}</p>
        <p>{{ validationError.description }}</p>
      </div>
    </dialog-component>
  </dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue';
import InputImage from '@/components/forms/InputImage.vue';
import ImageCropper from '@/components/forms/ImageCropper.vue';
import UploadImagePreview from '@/components/UploadImagePreview.vue';
import TextareaComponent from '@/components/forms/TextareaComponent.vue';
import {mapActions, mapState, mapWritableState} from 'pinia';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import {useHomeStore} from '@/stores/home.js';
import AvatarCircle from '@/components/AvatarCircle.vue';
import CreatePhotoMedia from '@/components/create-post/CreatePhotoMedia.vue';
import CreateVideoMedia from '@/components/create-post/CreateVideoMedia.vue';
import UploadVideoButton from '@/components/create-post/UploadVideoButton.vue';
import {useVideoUploadStore} from '@/stores/videoUpload.js';
import FormComponent from '@/components/forms/FormComponent.vue';
import {useCreatePostStore} from '@/stores/createPost.js';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
  name: 'CreatePostDialog',
  components: {
    FormComponent,
    UploadVideoButton,
    CreateVideoMedia,
    AvatarCircle,
    CreatePhotoMedia,
    TextareaComponent,
    UploadImagePreview,
    ImageCropper,
    InputImage,
    DialogComponent,
    ButtonComponent,
  },
  data() {
    return {
      showAudienceModal: false,
    };
  },
  computed: {
    ...mapWritableState(useCreatePostStore, ['post']),
    ...mapState(useCreatePostStore, [
      'isEditing',
      'isSending',
      'isCreating',
      'fullBase64',
      'audienceList',
      'croppedBase64',
      'hasAudienceList',
      'validationError',
      'showValidationDialog',
    ]),
    ...mapState(useGirlProfileStore, ['id', 'nickname', 'avatar']),
    hasAttachedVideo() {
      const {video, video_id, video_preview_id} = this.post;
      return Boolean(video_id || video_preview_id || video);
    },
    hasAttachedPhoto() {
      return Boolean(this.fullBase64 || this.croppedBase64 || this.post.photo);
    },
    hasAttachedMedia() {
      return this.hasAttachedPhoto || this.hasAttachedVideo;
    },
  },
  methods: {
    ...mapActions(useVideoUploadStore, ['resetVideo']),
    ...mapActions(useCreatePostStore, [
      'reset',
      'setMode',
      'getAudienceList',
      'editPostWithPhoto',
      'editPostWithVideo',
      'submitPostWithPhoto',
      'createPostWithVideo',
      'hideValidationDialog',
    ]),
    ...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
    ...mapActions(useHomeStore, ['getPosts']),
    onClose() {
      if (this.isEditing) {
        this.setMode('');
        this.reset();
      }
      this.$emit('close');
    },
    onCrop(e) {
      this.post.crop = e;
    },
    closeAudienceModal() {
      this.showAudienceModal = false;
    },
    openAudienceModal() {
      this.showAudienceModal = true;
    },
    async onSubmitPostWithPhoto() {
      try {
        if (this.isEditing) {
          await this.editPostWithPhoto();
        } else {
          await this.submitPostWithPhoto();
        }
        await this.onSuccessCreate();
      } catch (error) {
        this.$refs.form.setErrors(error.response.data.errors);
      }
    },
    async onSubmitPostWithVideo() {
      try {
        if (this.isEditing) {
          await this.editPostWithVideo();
        } else {
          await this.createPostWithVideo();
        }
        this.resetVideo();
        await this.onSuccessCreate();
      } catch (error) {
        this.$refs.form.setErrors(error.response.data.errors);
      }
    },
    async onSuccessCreate() {
      this.closeCreatePostDialog();
      this.setMode('');
      if (this.$route.name === 'home') {
        await this.getPosts();
      } else {
        this.$router.push({name: 'home'});
      }
    },
  },
  mounted() {
    this.getAudienceList();
  },
};
</script>

<style>
.create-post-description .form-field {
  margin-bottom: 9px;
  outline: none;
  min-height: 134px;
}

.create-post-description label {
  display: none;
}

.create-post-description .validation-list {
  margin-bottom: 14px;
}

.create-post-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.create-post-avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
}

.create-post-nickname {
  font-weight: 700;
}

.create-post-top-btn {
  font-size: 14px;
  color: var(--red);
}

.create-post .upload-image-preview {
  margin-bottom: 20px;
}

.create-post-image-controls {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 13px;
}

.create-post-control {
  font-size: 35px;
  display: flex;
  background: rgba(25, 28, 34, 0.5);
  border-radius: 50%;
  color: #fff;
  border: none;
  padding: 9px;
}

.create-post-crop-description {
  font-size: 16px;
  line-height: 20px;
  margin: 32px 0;
  text-align: center;
  color: var(--text-gray);
}

.create-post-radio {
  padding: 4px 0;
}

.create-post-radio input:checked + span {
  color: var(--red-secondary);
}

.create-post-radio:not(:last-child) {
  margin-bottom: 12px;
}

.create-post .dialog-body {
  max-height: calc(100vh - 144px);
  overflow: scroll;
}

.create-post-error {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray);
}

.create-post p:not(:last-child) {
  margin-bottom: 10px;
}

.create-post .input-image {
  visibility: hidden;
}

.create-post .input-image .validation-list {
  display: none;
}
</style>
