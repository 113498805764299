<template>
  <div class="media-video">
    <video
      playsinline
      class="video placeholder-bg"
      :src="`${video.file}#t=0.001`"
      v-lazy.poster="video.preview.url"
      :width="video.preview.width"
      :height="video.preview.height"
      preload="metadata"
      :muted="muted"
      loop="loop"
      :ref="`video${id}`"
      @play="onPlay"
      @playing="onPlaying"
      @waiting="onWaiting"
      @loadedmetadata="onLoadedMetaData($event)"
      @timeupdate="durationChange($event)"
      @error="onError"
    />
    <video-processing-notice v-if="noVideoFile">
      Видео обрабатывается
    </video-processing-notice>
    <template v-else>
      <div class="post-video-play" v-if="showPlayButton" @click="onRetry" />
      <div class="post-media-meta" v-if="showTimer">
        {{ `0:${progress}` }}
      </div>
      <div v-if="showLoader" class="post-media-meta">
        <loader class="video-loader" />
      </div>
    </template>
    <div v-if="showIcon" class="post-video-icon">
      <img src="@/assets/images/icons/camera.svg" alt="" />
    </div>
    <div
      class="media-video-volume"
      :class="{muted: muted}"
      @click="toggleVolume"
    />
  </div>
</template>

<script>
import videoObserver from '@/helpers/videoObserver.js';
import Loader from '@/components/Loader.vue';
import VideoProcessingNotice from '@/components/VideoProcessingNotice.vue';

export default {
  name: 'PostMediaVideo',
  components: {VideoProcessingNotice, Loader},
  props: {
    video: Object,
    id: Number,
  },
  data() {
    return {
      showPlayButton: false,
      showLoader: false,
      showIcon: true,
      muted: true,
      showTimer: false,
      duration: null,
      currentTIme: null,
    };
  },
  computed: {
    progress() {
      let result = Math.ceil(this.duration - this.currentTIme);
      return result < 10 ? `0${result}` : result;
    },
    currentVideo() {
      return this.$refs[`video${this.id}`];
    },
    noVideoFile() {
      return this.video.file === null;
    },
  },
  methods: {
    addVideoToObserver() {
      videoObserver.observe(this.currentVideo);
    },
    onError() {
      this.showPlayButton = true;
      this.showLoader = false;
      this.showIcon = true;
      this.showTimer = false;
    },
    async onRetry() {
      this.showPlayButton = false;
      await this.currentVideo.load();
      this.currentVideo.play();
    },
    onPlay() {
      this.showLoader = true;
      this.showIcon = false;
    },
    onPlaying() {
      this.showPlayButton = false;
      this.showLoader = false;
      this.showTimer = true;
    },
    onWaiting() {
      this.showTimer = false;
      this.showLoader = true;
    },
    durationChange(e) {
      this.currentTIme = e.target.currentTime;
    },
    onLoadedMetaData(e) {
      this.duration = e.target.duration;
    },
    toggleVolume() {
      this.muted = !this.muted;
    },
  },
  mounted() {
    this.addVideoToObserver();
  },
};
</script>

<style>
.media-video {
  overflow: hidden;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.media-video video {
  width: 100%;
  height: auto;
}

.media-video-volume {
  position: absolute;
  z-index: 10;
  right: 16px;
  bottom: 16px;
  width: 24px;
  height: 24px;
  background-color: rgba(25, 28, 34, 0.5);
  border-radius: 50%;
  background-image: url('@/assets/images/icons/volume-on.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.media-video-volume.muted {
  background-image: url('@/assets/images/icons/volume-off.svg');
}
</style>
