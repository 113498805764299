<template>
  <div class="post flex column start" :id="id">
    <div class="post-header flex row space container">
      <router-link :to="girlProfileLink" class="post-author-info">
        <avatar-circle :image-path="author.avatar" />
        <div class="post-data flex column center">
          <div class="post-name" @click="onClickNickname">
            {{ author.nickname }}
          </div>
          <div class="post-release">{{ published_text }}</div>
        </div>
      </router-link>
      <subscribe-button
        :id="author.id"
        v-if="isConsumer"
        btn-class="post-subscribe"
        :is-subscribed="author.has_in_fans_current_consumer"
        :is-waiting-subscription-result="isWaitingSubscriptionResult"
      />
      <post-status-for-girl
        v-if="isGirl"
        :post-id="id"
        :audience="audience"
        :publication_status="publication_status"
        :rejection_reason="rejection_reason"
      />
    </div>
    <text-with-hashtags-and-mentioned
      class="post-description"
      :text="description"
      :hashtags="hashtags"
      :mentioned="mentioned"
    />
    <div v-if="attachments" class="post-media" style="position: relative" @contextmenu="(e) => e.preventDefault()">
      <component :is="`post-media-${type}`" v-bind="attachments" :id="id" />
      <div v-if="showEditButton" class="post-edit-icon">
        <i class="i-pen" @click="onEdit" />
      </div>
      <div v-if="isBluredLoader" class="blur flex center align">
        <div class="i-spinner rotating post-subscribe-spinner" />
      </div>
      <div v-if="isLocked" class="lock big" @click="openSubscriptionPage" />
    </div>
    <div v-if="isPublishedPost" class="post-like-panel flex row space">
      <div class="flex row space">
        <post-like v-if="isConsumer" :amount="total_consumer_likes" :post-id="id" />
        <router-link :to="postPage" class="post-text-sm" v-if="isGirl && anyCommentNotLiked && isOwnPost">
          Выбери лучший комментарий
        </router-link>
      </div>
      <router-link :to="{name: 'post-likes', params: {id: this.id}}" class="post-like-counter">
        <span class="text-small">Нравится:</span>
        <span class="count">{{ total_likes }}</span>
      </router-link>
    </div>
    <slot v-if="isPublishedPost" name="comments">
      <div class="post-comments">
        <comment-component
          v-if="comment"
          :preview-letters-count="70"
          :nickname="comment.author_nickname"
          :text="comment.text"
          :author-id="comment.author_id"
          :liked-avatar="author.avatar"
          :liked-at-text="girl_liked_any_comment ? ' ' : ''"
          :mentioned="comment.mentioned"
        />
        <router-link :to="postPage" class="see-all">
          <template v-if="total_comments">
            Смотреть все комментарии
            <template>({{ total_comments }})</template>
          </template>
          <template v-if="total_comments === 0 && isConsumer"> Прокомментировать </template>
        </router-link>
      </div>
    </slot>
  </div>
</template>

<script>
import CommentComponent from '@/components/CommentComponent.vue';
import PostMediaCarousel from '@/components/PostMediaCarousel.vue';
import PostMediaVideo from '@/components/PostMediaVideo.vue';
import PostMediaImage from '@/components/PostMediaImage.vue';
import PostFavorite from '@/components/PostFavorite.vue';
import PostLike from '@/components/PostLike.vue';
import SubscribeButton from '@/components/SubscribeButton.vue';
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue';
import AvatarCircle from '@/components/AvatarCircle.vue';
import PostStatusForGirl from '@/components/PostStatusForGirl.vue';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import {mapActions, mapState, mapWritableState} from 'pinia';
import {useCreatePostStore} from '@/stores/createPost.js';
import {useProfileStore} from '@/stores/profile.js';

export default {
  name: 'PostComponent',
  components: {
    PostStatusForGirl,
    TextWithHashtagsAndMentioned,
    AvatarCircle,
    SubscribeButton,
    CommentComponent,
    PostMediaCarousel,
    PostMediaVideo,
    PostMediaImage,
    PostFavorite,
    PostLike,
  },
  props: {
    author: Object,
    comment: Object,
    isFavorite: Boolean,
    total_likes: Number,
    total_comments: Number,
    total_consumer_likes: Number,
    description: String,
    hashtags: Array,
    mentioned: Object,
    published_text: String,
    id: Number,
    attachments: Object,
    audience: String,
    visibility: String,
    girl_liked_any_comment: Boolean,
    rejection_reason: String,
    publication_status: {
      type: String,
      default: '',
    },
    onClickNicknameFunction: Function,
  },
  computed: {
    ...mapState(useProfileStore, {consumerId: 'id'}),
    ...mapWritableState(useCreatePostStore, ['post']),
    isOwnPost() {
      const girlProfileStore = useGirlProfileStore();
      const currentGirlId = girlProfileStore.id;
      return this.postAuthorId === currentGirlId;
    },
    isLocked() {
      return this.visibility === 'lock' && !this.isWaitingSubscriptionResult;
    },
    isBluredLoader() {
      return this.visibility === 'lock' && this.isWaitingSubscriptionResult;
    },
    type() {
      if (this.attachments.type === 'photo') {
        return this.attachments.photos.length > 1 ? 'carousel' : 'image';
      }
      return 'video';
    },
    postAuthorId() {
      return this.author.id;
    },
    girlProfileLink() {
      return {name: 'author-profile', params: {id: this.postAuthorId}};
    },
    isPublishedPost() {
      return this.publication_status === 'published';
    },
    isConsumer() {
      return this.$auth.check('consumer');
    },
    isGirl() {
      return this.$auth.check('girl');
    },
    postPage() {
      return {name: 'post', params: {id: this.id}};
    },
    anyCommentNotLiked() {
      return this.total_comments > 0 && !this.girl_liked_any_comment;
    },
    hasAttachedFile() {
      if (this.type === 'video') {
        return this.attachments.video.file !== null;
      }
      return this.attachments.photos.length > 0;
    },
    showEditButton() {
      return this.isOwnPost && this.isGirl && this.hasAttachedFile;
    },
    isWaitingSubscriptionResult() {
      return this.author.waiting_subscription_result;
    },
    subLink() {
      const subLink = import.meta.env.VITE_SUBS_URL;
      if (this.audience === 'all') {
        return subLink;
      }
      if (this.audience === 'fans') {
        const host = import.meta.env.VITE_API_URL;
        return `${host}consumers/${this.consumerId}/girls/${this.postAuthorId}/subscribe`;
      }
      return '';
    },
  },
  methods: {
    ...mapActions(useCreatePostStore, ['setPost', 'setMode']),
    openSubscriptionPage() {
      window.location.href = this.subLink;
    },
    onClickNickname(e) {
      e.preventDefault();
      if (this.onClickNicknameFunction) {
        this.onClickNicknameFunction(this.author.nickname);
        return;
      }
      this.$router.push(this.girlProfileLink);
    },
    onEdit() {
      let post = {};
      if (this.type === 'image') {
        post = {
          photo_id: this.attachments.photos[0].id,
          photo: this.attachments.photos[0].url,
          description: this.description,
          audience: this.audience,
        };
      }
      if (this.type === 'video') {
        post = {
          video_id: this.attachments.video.id,
          video: this.attachments.video.file,
          video_preview_id: this.attachments.video.preview.id,
          description: this.description,
          audience: this.audience,
        };
      }
      this.setPost(post, this.id);
    },
  },
};
</script>

<style>
.post-media {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.post-edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 53px;
  height: 53px;
  font-size: 26px;
  background: rgba(25, 28, 34, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
