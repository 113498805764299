<template>
  <div>
    <span v-if="isPublishedPost" class="post-subscribe">
      {{ postAudience }}
    </span>
    <template v-else>
      <button
        class="post-subscribe"
        v-if="girlNeedsVerification"
        @click="showVerificationNeedsDialog = true"
      >
        Верифицировать
      </button>
      <button
        class="post-subscribe"
        v-if="girlVerificationRejected"
        @click="showVerificationRejectedDialog = true"
      >
        Верифицировать
      </button>
      <button
        class="post-subscribe invert"
        v-if="girlVerificationInProcess"
        @click="showVerificationProcessDialog = true"
      >
        На верификации
      </button>
      <span v-if="postInModeration" class="post-subscribe invert">
        На модерации
      </span>
      <button
        v-if="postRejected"
        class="post-subscribe"
        @click="showPostRejectedDialog = true"
      >
        Отклонен
      </button>
    </template>
    <dialog-component
      v-if="showVerificationNeedsDialog"
      @close="showVerificationNeedsDialog = false"
    >
      <template #header-title>Требуется верификация.</template>
      <p class="status-dialog-text">
        Мы должны убедиться, что ты — настоящая. Приготовься сделать селфи с
        паспортом. После проверки тебе станет доступен просмотр чужих постов, а
        твои собственные посты опубликуются автоматически.
      </p>
      <ButtonComponent :loading="isSending" @click="startVerification">
        Начать верификацию
      </ButtonComponent>
    </dialog-component>
    <dialog-component
      v-if="showVerificationProcessDialog"
      @close="showVerificationProcessDialog = false"
    >
      <template #header-title>Верификация. Почти готово.</template>
      <p class="status-dialog-text">
        Нужно немного подождать, пока мы проверим данные. Обычно процесс
        занимает не более 8 часов. После этого тебе станет доступен просмотр
        чужих постов, а твои собственные посты опубликуются автоматически.
      </p>
      <ButtonComponent
        :loading="isSending"
        @click="showVerificationProcessDialog = false"
      >
        Отлично
      </ButtonComponent>
    </dialog-component>
    <dialog-component
      v-if="showVerificationRejectedDialog"
      @close="showVerificationRejectedDialog = false"
    >
      <template #header-title>Требуется верификация.</template>
      <p class="status-dialog-text">
        Мы не смогли убедиться, что ты — настоящая. Попробуй повторить шаги
        верификации.
      </p>
      <ButtonComponent :loading="isSending" @click="startVerification">
        Повторить верификацию
      </ButtonComponent>
    </dialog-component>
    <dialog-component
      v-if="showPostRejectedDialog"
      @close="showPostRejectedDialog = false"
    >
      <template #header-title>Пост отклонен.</template>
      <p v-if="rejection_reason" class="status-dialog-text text-red">
        От модератора: {{ rejection_reason }}
      </p>
      <p class="status-dialog-text">
        Можешь отредактировать этот пост или удалить, чтобы не мешал.
      </p>
      <ButtonComponent :loading="isSending" @click="onDelete">
        Удалить
      </ButtonComponent>
    </dialog-component>
  </div>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue';
import {mapActions} from 'pinia';
import {useModalStore} from '@/stores/modal.js';
import {usePostStore} from '@/stores/post.js';
import {useGirlProfileStore} from '@/stores/girlProfile.js';
import ButtonComponent from './ButtonComponent.vue';

const audienceMap = {
  all: 'Виден всем',
  fans: 'Только фанатам',
  hidden: 'Скрыт',
};

export default {
  name: 'PostStatusForGirl',
  components: {DialogComponent, ButtonComponent},
  props: {
    postId: Number,
    audience: String,
    publication_status: String,
    rejection_reason: String,
  },
  data() {
    return {
      showVerificationNeedsDialog: false,
      showVerificationProcessDialog: false,
      showVerificationRejectedDialog: false,
      showPostRejectedDialog: false,
      isSending: false,
    };
  },
  computed: {
    verification_status() {
      const girlProfileStore = useGirlProfileStore();
      return girlProfileStore.verification_status;
    },
    girlNeedsVerification() {
      return this.verification_status === 'needs';
    },
    girlVerificationInProcess() {
      return this.verification_status === 'in_process';
    },
    girlVerificationRejected() {
      return this.verification_status === 'rejected';
    },
    postInModeration() {
      return this.publication_status === 'in_moderation';
    },
    postRejected() {
      return this.publication_status === 'rejected';
    },
    postAudience() {
      return audienceMap[this.audience];
    },
    isPublishedPost() {
      return this.publication_status === 'published';
    },
  },
  methods: {
    ...mapActions(useModalStore, ['openVerificationDialog']),
    ...mapActions(usePostStore, ['deletePost']),
    startVerification() {
      this.openVerificationDialog();
      this.showVerificationNeedsDialog = false;
      this.showVerificationRejectedDialog = false;
    },
    async onDelete() {
      this.isSending = true;
      await this.deletePost(this.postId);
      this.showPostRejectedDialog = false;
      this.isSending = false;
    },
  },
};
</script>

<style>
.status-dialog-text {
  margin-bottom: 26px;
  color: var(--text-gray);
  font-size: 16px;
  line-height: 20px;
}
</style>
