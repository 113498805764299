<template>
  <div class="dialog-backdrop">
    <div :class="['dialog', {'dialog-fullheight': fullheight}]">
      <div class="dialog-header">
        <h3 class="dialog-title">
          <slot name="header-title" />
        </h3>
        <button @click="$emit('close')" class="dialog-close-btn">
          <slot name="header-icon">
            <i class="i-cross" />
          </slot>
        </button>
      </div>
      <div :class="['dialog-body', {'no-padding': nopadding}]">
        <slot />
      </div>
      <div :class="['dialog-footer', {'no-padding': nopadding}]">
        <slot name="dialog-footer" />
      </div>
    </div>
  </div>
</template>

<script>
import bodyScroll from '@/helpers/bodyScroll.js';

export default {
  name: 'DialogComponent',
  props: {
    fullheight: {
      type: Boolean,
      default: () => false,
    },
    nopadding: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    bodyScroll.hidden();
  },
  beforeDestroy() {
    bodyScroll.auto();
  },
};
</script>

<style>
.dialog-backdrop {
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.dialog {
  position: fixed;
  bottom: 0;
  max-width: 500px;
  background: var(--primary);
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 10px 0;
  max-height: 100%;
  overflow: scroll;
}

.dialog-fullheight {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-fullheight .dialog-body {
  flex: 1;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.dialog-body,
.dialog-footer {
  padding: 10px 20px;
}

.no-padding {
  padding: 0;
}

.dialog-body:empty,
.dialog-footer:empty {
  display: none;
}

.dialog-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: var(--text-gray);
}

.dialog-close-btn {
  background: transparent;
  padding: 0;
  border: none;
  color: #727281;
  font-size: 24px;
  cursor: pointer;
  display: flex;
}
</style>
