import {getAwards, getMoreAwards} from './awards.js';
import {getComments, getMoreComments} from './comments.js';
import {getConsumer} from './consumer.js';
import {getFollowingGirls} from './girls.js';
import {getPostsWithTotalConsumerLikes, getMorePostsWithTotalConsumerLikes} from './postsWithLikes.js';
import {getGirlsWithTotalLikesByPosts, getMoreGirlsWithTotalLikesByPosts} from './girlsWithLikes.js';
import {getLikedPosts, getMoreLikedPosts} from './likes.js';
import {getNotifications, getMoreNotifications} from '@/http/consumer/notifications.js';
import {updateProfile, loadAvatar, loadCover} from './updateProfile.js';
import {enableSmsNotification, disableSmsNotification} from '@/http/consumer/smsNotification.js';
import {sendComplaint} from '@/http/consumer/complaints.js';
import {getProfile} from '@/http/consumer/profile.js';
import {startWaitingSubscription} from '@/http/consumer/startWaitingSubscription.js';
import {getReceipts, getMoreReceipts} from '@/http/consumer/receipts.js';
import {getSubscriptions, getMoreSubscriptions} from '@/http/consumer/subscriptions.js';
import {unsubscribeFromService} from '@/http/consumer/unsubscribeFromService.js';

export default {
  getFollowingGirls,
  getAwards,
  getMoreAwards,
  getComments,
  getMoreComments,
  getConsumer,
  getPostsWithTotalConsumerLikes,
  getMorePostsWithTotalConsumerLikes,
  getGirlsWithTotalLikesByPosts,
  getMoreGirlsWithTotalLikesByPosts,
  getLikedPosts,
  getMoreLikedPosts,
  getNotifications,
  getMoreNotifications,
  updateProfile,
  loadAvatar,
  loadCover,
  enableSmsNotification,
  disableSmsNotification,
  sendComplaint,
  getProfile,
  startWaitingSubscription,
  getReceipts,
  getMoreReceipts,
  getSubscriptions,
  getMoreSubscriptions,
  unsubscribeFromService,
};
