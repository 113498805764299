import {defineStore} from 'pinia';
import postsApi from '@/http/posts';
import consumerApi from '@/http/consumer';
import {useGirlStore} from '@/stores/girl.js';
import {useHomeStore} from '@/stores/home.js';
import {useConsumerStore} from '@/stores/consumer.js';
import {usePostStore} from '@/stores/post.js';
import {useSearchStore} from '@/stores/search.js';
import {getMoreData} from '@/stores/helper.js';

export const useProfileStore = defineStore('profile', {
  state: () => ({
    isProfileLoaded: false,
    balance: 0,
    id: null,
    nickname: '',
    about_yourself: '',
    avatar: '',
    avatar_id: null,
    cover: '',
    cover_id: null,
    msisdn: null,
    total_likes: 0,
    total_subscriptions: 0,
    sms_notification_on: null,
    followingGirls: null,
    has_subscription_to_service: false,
    notifications: 0,
    notificationList: null,
    subscriptionsData: null,
    isLoading: false,
  }),
  getters: {
    notificationsHasLoaded: (state) => state.notificationList !== null,
    followingGirlsHasLoaded: (state) => state.followingGirls !== null,
    subscriptionGirls: (state) => state.subscriptionsData?.data?.girls,
    hasServiceSubscription: (state) => state.subscriptionsData?.data?.service?.is_subscribed,
  },
  actions: {
    async getProfile() {
      const result = await consumerApi.getProfile();
      if (result) {
        this.isProfileLoaded = true;
        Object.assign(this.$state, result);
        await this.getFollowingGirls();
      }
    },
    async getFollowingGirls() {
      const result = await consumerApi.getFollowingGirls(this.id);
      if (result) {
        this.followingGirls = result;
      }
    },
    async getNotifications() {
      this.isLoading = true;
      const result = await consumerApi.getNotifications();
      if (result) {
        this.notifications = 0;
        this.notificationList = result;
      }
      this.isLoading = false;
    },
    async getMoreNotifications() {
      await getMoreData(this.notificationList, consumerApi.getMoreNotifications);
    },
    async setLike(postId) {
      const modelStore = useGirlStore();
      const homeStore = useHomeStore();
      const consumerStore = useConsumerStore();
      const postStore = usePostStore();
      const searchStore = useSearchStore();
      const result = await postsApi.like(postId);
      if (result) {
        const {girl, post, consumer} = result;
        this.balance = consumer.balance_likes;
        modelStore.updateLikes(girl, post);
        homeStore.updateLikes(post);
        consumerStore.updateLikes(post);
        postStore.incrementTotalLikes();
        searchStore.updateLikes(post);
      }
    },
    async updateProfile(formData) {
      this.isLoading = true;
      const result = await consumerApi.updateProfile(formData);
      if (result) {
        Object.assign(this.$state, formData);
      }
      this.isLoading = false;
    },
    async loadAvatar(formData) {
      this.isLoading = true;
      try {
        const result = await consumerApi.loadAvatar(formData);
        Object.assign(this.$state, result);
        return result;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async loadCover(formData) {
      this.isLoading = true;
      try {
        const result = await consumerApi.loadCover(formData);
        Object.assign(this.$state, result);
        return result;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async enableSmsNotification() {
      const result = await consumerApi.enableSmsNotification();
      if (result) {
        this.sms_notification_on = true;
      }
    },
    async disableSmsNotification() {
      const result = await consumerApi.disableSmsNotification();
      if (result) {
        this.sms_notification_on = false;
      }
    },
    async getSubscriptions() {
      try {
        this.isLoading = true;
        this.subscriptionsData = await consumerApi.getSubscriptions();
      } finally {
        this.isLoading = false;
      }
    },

    async getMoreSubscriptions() {
      try {
        this.isLoading = true;
        const result = await consumerApi.getSubscriptions();
        Object.assign(this.subscriptionsData, result);
      } finally {
        this.isLoading = false;
      }
    },
    removeSubscription(girlId) {
      this.subscriptionsData.data.girls = this.subscriptionsData.data.girls.filter(({id}) => girlId !== id);
    },
    async unsubscribeFromService() {
      try {
        this.isLoading = true;
        await consumerApi.unsubscribeFromService();
        this.subscriptionsData.data.service.is_subscribed = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
