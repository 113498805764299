import {http} from '@/http/index.js';

async function getFans(girlId) {
  return http
    .get(`/girls/${girlId}/fans-with-likes`)
    .then((response) => response.data);
}

async function getMoreFans(url) {
  return http.get(url).then((response) => response.data);
}

export {getFans, getMoreFans};
