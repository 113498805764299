<template>
  <div class="comment">
    <div class="flex">
      <router-link v-if="showAvatar" :to="consumerProfileLink">
        <avatar-circle :image-path="avatar" />
      </router-link>
      <div class="w-100">
        <span @click="onClickNickname" class="comment-nickname">
          {{ nickname }}
        </span>
        <text-with-hashtags-and-mentioned
          class="comment-text"
          :text="text"
          :preview-letters-count="previewLettersCount"
          :mentioned="mentioned"
        />
        <div class="release">{{ createdAt }}</div>
      </div>
    </div>
    <slot name="append">
      <div v-if="likedAtText" class="comment-like" v-lazy.background="likedAvatar" />
    </slot>
  </div>
</template>

<script>
import AvatarCircle from '@/components/AvatarCircle.vue';
import CollapsedText from '@/components/CollapsedText.vue';
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue';

export default {
  name: 'CommentComponent',
  components: {CollapsedText, AvatarCircle, TextWithHashtagsAndMentioned},
  props: {
    avatar: String,
    authorId: Number,
    nickname: String,
    createdAt: String,
    text: String,
    likedAvatar: String,
    likedAtText: String,
    previewLettersCount: Number,
    onClickNicknameFunction: Function,
    mentioned: Object,
  },
  computed: {
    consumerProfileLink() {
      return {name: 'consumer-profile', params: {id: this.authorId || 0}};
    },
    showAvatar() {
      return this.authorId && this.avatar;
    },
  },
  methods: {
    onClickNickname() {
      if (this.onClickNicknameFunction) {
        this.onClickNicknameFunction(this.nickname);
        return;
      }
      this.$router.push({
        name: 'consumer-profile',
        params: {id: this.authorId || 0},
      });
    },
  },
};
</script>
