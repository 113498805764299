const routes = [
  {
    path: '/consumer/:id',
    component: () => import('@/views/Consumer/Index.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/consumer/subscriptions',
        name: 'consumer-subscriptions',
        component: () => import('@/views/Consumer/SubscriptionsView.vue'),
        meta: {
          layout: 'arrow-back',
          title: 'Отключить подписку',
        },
      },
      {
        path: '/consumer/notifications',
        name: 'consumer-notifications',
        component: () => import('@/views/Consumer/NotificationsView.vue'),
      },
      {
        path: '/consumer/profile/edit',
        name: 'consumer-edit',
        component: () => import('@/views/Consumer/ProfileEditView.vue'),
        meta: {
          layout: 'empty',
        },
      },
      {
        path: '',
        component: () => import('@/views/Consumer/ProfileView.vue'),
        children: [
          {
            path: '',
            name: 'consumer-profile',
            component: () => import('@/views/Consumer/AwardThumbsView.vue'),
          },
          {
            path: 'comments',
            name: 'consumer-reactions',
            component: () => import('@/views/Consumer/CommentsView.vue'),
          },
          {
            path: 'likes',
            name: 'consumer-likes',
            component: () => import('@/views/Consumer/LikedPostsThumbsView.vue'),
          },
        ],
      },
      {
        path: 'girls-with-total-likes-by-posts',
        name: 'consumer-girls-with-total-likes-by-posts',
        component: () => import('@/views/Consumer/GirlsWithTotalLikesByPostsView.vue'),
      },
      {
        path: 'posts-with-total-consumer-likes',
        name: 'posts-with-total-consumer-likes',
        component: () => import('@/views/Consumer/PostsWithTotalConsumerLikes.vue'),
      },
      {
        path: 'award-posts',
        name: 'award-posts',
        component: () => import('@/views/Consumer/AwardPostsView.vue'),
        meta: {
          layout: 'balanceWithMenu',
          title: 'Награды',
        },
      },
      {
        path: 'liked-posts',
        name: 'liked-posts',
        component: () => import('@/views/Consumer/LikedPostsView.vue'),
        meta: {
          layout: 'balanceWithMenu',
          title: 'Лайки',
        },
      },
    ],
  },
  {
    path: '/receipts',
    name: 'receipts',
    component: () => import('@/views/Consumer/ReceiptsView.vue'),
    meta: {
      layout: 'arrowBack',
      title: 'Электронные чеки',
    },
  },
  {
    path: '/subswait',
    name: 'subswait',
    component: () => import('@/views/SubswaitView.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
  {
    path: '/login-if-has-any-subscription-to-girl',
    name: 'login-if-has-any-subscription-to-girl',
    component: () => import('@/views/LoginIfHasAnySubscriptionToGirlView.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
  {
    path: '/sign-in-as-test-consumer',
    name: 'sign-in-as-test-consumer',
    component: () => import('@/views/Consumer/SignInAsTestConsumer.vue'),
    meta: {
      auth: undefined,
      layout: 'empty',
    },
  },
  {
    path: '/',
    name: 'error-subscription-waiting-only-one-girl',
    component: () => import('@/views/Consumer/ErrorSubscriptionWaitingView.vue'),
    props: true,
    meta: {
      layout: 'logoOnly',
      auth: true,
    },
  },
  {
    path: '/',
    name: 'error-subscription-failed-only-one-girl',
    component: () => import('@/views/Consumer/ErrorSubscriptionFailedView.vue'),
    props: true,
    meta: {
      layout: 'logoOnly',
      auth: true,
    },
  },
  {
    path: '/error-subscribe',
    name: 'error-subscribe',
    component: () => import('@/views/ErrorSubscribe.vue'),
    props: true,
    meta: {
      auth: true,
      layout: 'logoOnly',
    },
  },
];

export default routes;
