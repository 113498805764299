<template functional>
  <div
    class="history-item history-item-lg opacity"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="image-container">
      <div class="image">
        <div class="history-item-add">+</div>
      </div>
    </div>
    <div class="history-item-name">Увидеть всех</div>
  </div>
</template>

<script>
export default {
  name: 'FilterCardButton',
};
</script>
