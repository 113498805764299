<template>
  <div v-if="appLoaderVisibility" class="app-loader" />
</template>

<script>
import {mapState} from 'pinia';
import {useModalStore} from '@/stores/modal.js';

export default {
  name: 'TheAppLoader',
  computed: {
    ...mapState(useModalStore, ['appLoaderVisibility']),
  },
};
</script>

<style>
.app-loader {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
