<script lang="jsx">
export default {
  name: 'ButtonComponent',
  functional: true,
  props: {
    tag: {
      type: String,
      default: () => 'button',
    },
    color: {
      type: String,
      default: () => 'primary',
    },
    className: {
      type: String,
      default: () => '',
    },
    shrink: {
      type: Boolean,
      default: () => false,
    },
    small: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => 'button',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    to: {
      type: [String, Object],
    },
    for: {
      type: String,
    },
    href: {
      type: String,
    },
  },
  render(h, {props, slots, listeners}) {
    const Tag = props.tag;
    const classList = ['btn', `btn-${props.color}`, props.className];
    if (props.shrink) {
      classList.push('btn-shrink');
    }
    if (props.small) {
      classList.push('btn-small');
    }
    const clickHandler = () => {
      if (props.disabled || props.loading) {
        return null;
      }
      return listeners.click ? listeners.click() : null;
    };
    return (
      <Tag
        type={props.type}
        to={props.to}
        for={props.for}
        href={props.href}
        class={classList}
        disabled={props.disabled || props.loading}
        onClick={clickHandler}
      >
        {props.loading ? <i class="i-spinner rotating" /> : slots().default}
      </Tag>
    );
  },
};
</script>

<style lang="scss">
.btn {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  gap: 10px;
  height: 52px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border: none;
  font-family: inherit;
  color: var(--white);
}

.btn-white {
  margin: 5px 0;
  background: var(--white);
  color: var(--primary);
}

.btn-transparent {
  border: 1px solid #363b4a;
  background: transparent;
}

.btn-primary-outline {
  color: var(--red-secondary);
  border: 1px solid var(--red-secondary);
  background: transparent;
}

.btn-transparent:disabled {
  color: #8f8c8c;
}

.btn-primary {
  background: var(--red);
}

.btn-secondary {
  background: var(--secondary);
}

.btn-shrink {
  width: auto;
}

.btn-small {
  font-weight: 500;
  padding: 8px 20px;
  height: 40px;
}
</style>
