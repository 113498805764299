<template>
  <div>
    <div class="fixed-top">
      <div class="header flex row space container">
        <header-logo />
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavMenu from '@/components/NavMenu.vue';
import {mapState} from 'pinia';
import {useModalStore} from '@/stores/modal.js';
import HeaderLogo from '@/components/HeaderLogo.vue';

export default {
  name: 'HomeLayout',
  components: {HeaderLogo, NavMenu, HeaderComponent},
  computed: {
    ...mapState(useModalStore, ['balanceNotification']),
  },
};
</script>
