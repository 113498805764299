<template>
  <div
    @click="onSelect"
    class="history-item"
    :class="{
      active: filter?.currentGirl === id,
      opacity: waiting_subscription_result,
    }"
  >
    <div class="image-container relative">
      <div
        class="image placeholder-bg"
      >
        <img rel="preload" fetchpriority="high" :src="avatar" alt="">
      </div>
      <div class="history-item-spinner" v-if="waiting_subscription_result">
        <i class="i-spinner rotating text-white" />
      </div>
    </div>
    <div class="history-item-name">{{ nickname }}</div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useHomeStore} from '@/stores/home.js';

export default {
  name: 'FilterCard',
  props: {
    nickname: String,
    avatar: String,
    id: Number,
    waiting_subscription_result: Boolean,
  },
  computed: {
    ...mapState(useHomeStore, ['filter']),
  },
  methods: {
    ...mapActions(useHomeStore, ['setFilter']),
    onSelect() {
      this.setFilter(this.id);
    },
  },
};
</script>

<style scoped>
.history-item .image {
  display: flex;
  overflow: hidden;
  justify-content: center;
}
.image img {
  height: 100%;
  object-fit: contain;
}
</style>