import {defineStore} from 'pinia';
import postsApi from '@/http/posts';
import {getMoreData} from '@/stores/helper.js';
import {useHomeStore} from '@/stores/home.js';
import {useSearchStore} from '@/stores/search.js';
import {useConsumerStore} from '@/stores/consumer.js';
import {useGirlStore} from '@/stores/girl.js';

export const usePostStore = defineStore('post', {
  state: () => ({
    attachments: null,
    author: {
      avatar: '',
      has_in_fans_current_consumer: null,
      id: null,
      nickname: '',
    },
    description: '',
    hashtags: [],
    id: null,
    is_favorite: null,
    mentioned: {},
    audience: null,
    published_text: '',
    total_comments: null,
    total_consumer_likes: null,
    total_likes: null,
    visibility: '',
    girl_liked_any_comment: null,
    publication_status: '',
    comments: {
      data: [],
      links: {},
      meta: {},
    },
    consumersWithLikes: null,
    isLoading: false,
    isReady: false,
  }),
  actions: {
    removeBlurFromPost() {
      this.author.has_in_fans_current_consumer = true;
    },
    applyBlurToPost() {
      this.author.has_in_fans_current_consumer = false;
    },
    incrementTotalComments() {
      this.comments.meta.total += 1;
    },
    incrementTotalLikes() {
      if (this.isReady) {
        this.total_consumer_likes += 1;
        this.total_likes += 1;
      }
    },
    async getPost(id) {
      this.isLoading = true;
      const result = await postsApi.getPost(id);
      if (result) {
        Object.assign(this.$state, result);
        this.isReady = true;
      }
      this.isLoading = false;
    },
    async getPostComments(id) {
      this.isLoading = true;
      const result = await postsApi.getPostComments(id);
      if (result) {
        Object.assign(this.comments, result);
      }
      this.isLoading = false;
    },
    async getMorePostComments() {
      await getMoreData(this.comments, postsApi.getMorePostComments);
    },
    async sendComment(id, comment) {
      this.isLoading = true;
      try {
        const result = await postsApi.sendComment(id, comment);
        this.comments.data.unshift(result);
        this.incrementTotalComments();
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async likeComment(commentId) {
      const result = postsApi.likeComment(commentId);
      if (result) {
        this.girl_liked_any_comment = true;
        this.comments.data
          .filter((comment) => comment.id === commentId)
          .map(({comment}) => {
            comment.liked_time_as_text = 'Только что';
          });
      }
    },
    async deletePost(postId) {
      const result = await postsApi.deletePost(postId);
      if (result) {
        const homeStore = useHomeStore();
        const searchStore = useSearchStore();
        const consumerStore = useConsumerStore();
        const girlStore = useGirlStore();
        homeStore.removePostFromList(postId);
        searchStore.removePostFromList(postId);
        consumerStore.removePostFromList(postId);
        girlStore.removePostFromList(postId);
      }
    },
    async getConsumersWithLikes(postId) {
      try {
        if (this.consumersWithLikes) {
          this.consumersWithLikes.data = [];
        }
        this.isLoading = true;
        this.consumersWithLikes = await postsApi.consumersWithLikes(postId);
      } finally {
        this.isLoading = false;
      }
    },
    async getMoreConsumersWithLikes() {
      await getMoreData(
        this.consumersWithLikes,
        postsApi.getMoreConsumersWithLikes
      );
    },
  },
});
