import {
  getPost,
  getPosts,
  getMorePosts,
  getPostComments,
  sendComment,
  getMorePostComments,
} from './posts.js';
import {searchPostsByDescription} from './searchPostsByDescription.js';
import {like} from './like.js';
import {
  getRecommendedPost,
  getMoreRecommendedPost,
} from '@/http/posts/recommended.js';
import {likeComment} from '@/http/posts/likeComment.js';
import {deletePost} from '@/http/posts/deletePost.js';
import {
  consumersWithLikes,
  getMoreConsumersWithLikes,
} from '@/http/posts/consumersWithLikes.js';

export default {
  like,
  getPost,
  getPosts,
  deletePost,
  likeComment,
  getMorePosts,
  getPostComments,
  sendComment,
  getMorePostComments,
  searchPostsByDescription,
  getRecommendedPost,
  getMoreRecommendedPost,
  consumersWithLikes,
  getMoreConsumersWithLikes,
};
