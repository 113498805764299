<template>
  <router-link
    :to="{name: 'author-statistics'}"
    class="money-balance rounded-button"
  >
    <img src="@/assets/images/icons/ruble.png" class="ruble-icon" />
    <span>{{ balanceToDisplay }} &#8381;</span>
  </router-link>
</template>

<script>
import {mapState} from 'pinia';
import {useGirlProfileStore} from '@/stores/girlProfile';

export default {
  name: 'MoneyBalance',
  computed: {
    ...mapState(useGirlProfileStore, ['balance']),
    balanceToDisplay() {
      return this.balance.toLocaleString('ru-RU', {useGrouping: true});
    },
  },
};
</script>

<style>
.money-balance {
  color: var(--white);
  font-size: 18px;
  display: flex;
  align-items: center;
}
.ruble-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
</style>
