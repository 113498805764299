const routes = [
  {
    path: '',
    component: () => import('@/views/Girl/Auth/AuthView.vue'),
    meta: {
      auth: false,
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Girl/Auth/AuthFormView.vue'),
        children: [
          {
            path: 'author/login',
            name: 'author-login',
            component: () => import('@/views/Girl/Auth/LoginView.vue'),
            meta: {layout: 'logoOnly'},
          },
          {
            path: 'author/registration',
            name: 'author-registration',
            component: () => import('@/views/Girl/Auth/RegistrationView.vue'),
            meta: {layout: 'logoOnly'},
          },
        ],
      },
      {
        path: 'author/confirm-email',
        name: 'author-confirm-email',
        component: () => import('@/views/Girl/Auth/ConfirmEmailView.vue'),
        meta: {layout: 'logoOnly'},
      },
      {
        path: 'author/forgot-password',
        name: 'author-forgot-password',
        component: () => import('@/views/Girl/Auth/ForgotPasswordView.vue'),
        meta: {layout: 'logoOnly'},
      },
      {
        path: 'author/reset-password',
        name: 'author-reset-password',
        component: () => import('@/views/Girl/Auth/ResetPasswordView.vue'),
        meta: {layout: 'logoOnly'},
      },
      {
        path: 'author/reset-link-has-sent',
        name: 'author-reset-link-has-sent',
        component: () => import('@/views/Girl/Auth/ResetLinkHasSent.vue'),
        meta: {layout: 'logoOnly'},
      },
      {
        path: 'author/email-verification',
        name: 'author-email-verification',
        component: () => import('@/views/Girl/Auth/EmailVerificationView.vue'),
        meta: {layout: 'empty', auth: undefined},
      },
      {
        path: 'author/email-verification-link-expired',
        name: 'author-email-verification-link-expired',
        component: () =>
          import('@/views/Girl/Auth/EmailVerificationExpiredView.vue'),
        meta: {layout: 'logoOnly', auth: undefined},
      },
    ],
  },
];

export default routes;
